<ng-container *ngIf="showLoader && !source">
	<app-spinner [message]="loadingMessage"></app-spinner>
</ng-container>

<ng-container *ngIf="source">
	<div [ngClass]="class">
		<div class="row">
			<div class="col">
				<h3>
					<span class="me-2">{{ heading }}</span>
					<span *ngIf="showCount">({{ source?.length || 0 }})</span>
				</h3>
			</div>

			<div class="col-auto">
				<ng-container *ngIf="submittedDate">
					<h4>
						<span class="badge rounded-pill text-bg-success">
							Submitted on {{ submittedDate | date }}
						</span>
					</h4>
				</ng-container>

				<ng-container *ngIf="action">
					<ng-container *ngTemplateOutlet="action.template"></ng-container>
				</ng-container>
			</div>
		</div>

		<div *ngIf="toolbar">
			<div class="btn-toolbar align-items-center mt-3" [ngClass]="toolbar.class">
				<ng-container *ngTemplateOutlet="toolbar.template"></ng-container>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col">
				<ng-content></ng-content>
			</div>
		</div>

		<div *ngIf="copy">
			<ng-container *ngTemplateOutlet="copy.template"></ng-container>
		</div>
	</div>
</ng-container>
