import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
	private storage: any;

	public constructor() {
		this.storage = localStorage;
	}

	public has(key: string): boolean {
		return (this.get(key) !== null);
	}

	public get(key: string): string {
		return localStorage.getItem(key);
	}

	public getObject(key: string): any {
		let item = this.get(key);

		if (item) {
			try {
				return JSON.parse(item);
			} catch (ex) {
				console.error(ex);
			}
		}

		return null;
	}

	public set(key: string, value: any): void {
		this.storage.setItem(key, value);
	}

	public setObject(key: string, value: any): void {
		this.set(key, JSON.stringify(value));
	}

	public remove(key: string): void {
		this.storage.removeItem(key);
	}
}
