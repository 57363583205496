import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RenewalYearsResolver } from '@frontend/shared/resolvers';

import { NotFoundPage } from '@frontend/core/pages';

const ROUTES: Routes = [{
	path: '',
	pathMatch: 'full',
	redirectTo: 'clubs',
}, {
	path: 'account',
	loadChildren: () => import('@frontend/account/module').then(m => m.AccountModule)
}, {
	path: 'approvals',
	loadChildren: () => import('./modules/approvals/module').then(m => m.ApprovalsModule),
	resolve: { renewalYear: RenewalYearsResolver }
}, {
	path: 'resources',
	loadChildren: () => import('./modules/resources/module').then(m => m.ResourcesModule),
	resolve: { renewalYear: RenewalYearsResolver }
}, {
	path: 'reports',
	loadChildren: () => import('./modules/reports/module').then(m => m.ReportsModule),
	resolve: { renewalYear: RenewalYearsResolver }
}, {
	path: 'profile',
	loadChildren: () => import('./modules/profile/module').then(m => m.ProfileModule),
	resolve: { renewalYear: RenewalYearsResolver }
}, {
	path: 'clubs',
	loadChildren: () => import('./modules/clubs/module').then(m => m.ClubsModule),
	resolve: { renewalYear: RenewalYearsResolver }
}, {
	path: 'maintenance',
	loadChildren: () => import('./modules/maintenance/module').then(m => m.MaintenanceModule)
}, {
	path: 'documents/:id/view',
	loadChildren: () => import('./modules/clubs/modules/documents/module').then(m => m.DocumentsModule)
}, {
	path: '**',
	component: NotFoundPage
}];

@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES, {
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule { }
