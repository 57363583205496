<ng-container *ngIf="showName">
	<app-input-text name="attentionName" [(ngModel)]="model.attentionName" [required]="required" [readonly]="readonly" [labelClass]="labelClass" [inputClass]="inputClass">
		Name/Attn
	</app-input-text>
</ng-container>

<app-input-text name="address1" [(ngModel)]="model.address1" [required]="required || requireAddress" [readonly]="readonly" [labelClass]="labelClass" [inputClass]="inputClass">
	Address 1
</app-input-text>

<app-input-text name="address2" [(ngModel)]="model.address2" [readonly]="readonly" [labelClass]="labelClass" [inputClass]="inputClass">
	<ng-container *ngIf="!showLongLabel">
		Address 2
	</ng-container>
	<ng-container *ngIf="showLongLabel">
		Address 2<br>
		<span class="text-secondary">(include unit # if applicable)</span> 
	</ng-container>
</app-input-text>

<app-input-select name="countryID" [(ngModel)]="model.countryID" (ngModelChange)="loadStatesForCountry()" [source]="countries" [itemValue]="'countryID'" [itemLabel]="'countryName'" [required]="required" [disabled]="!countries || readonly" [labelClass]="labelClass" [inputClass]="inputClass">
	Country
</app-input-select>

<app-input-text name="city" [(ngModel)]="model.city" [required]="required || requireCity" [readonly]="readonly" [labelClass]="labelClass" [inputClass]="inputClass">
	City
</app-input-text>

<div class="row">
	<label class="col-form-label text-end" for="stateID" [ngClass]="labelClass">
		State/Zip
	</label>

	<div [ngClass]="inputClass">
		<div class="row g-2">
			<div class="col-16">
				<app-input-select name="stateID" [(ngModel)]="model.stateID" [source]="countryStates" [itemValue]="'stateID'" [itemLabel]="'stateName'" [required]="required" [emptyHint]="'select a country with states'" [disabled]="!countryStates || !model.countryID || countryStates?.length === 0 || readonly" [labelClass]="'d-none'" [inputClass]="'col'">

				</app-input-select>
			</div>

			<div class="col-8">
				<app-input-text name="zip" [(ngModel)]="model.zip" [required]="required || requireZipCode" [mask]="zipMask" [readonly]="readonly" [labelClass]="'d-none'" [inputClass]="'col'">
					Zip
				</app-input-text>
			</div>
		</div>
	</div>
</div>
