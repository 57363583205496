import { Injectable, Injector } from '@angular/core';
import { environment } from '@frontend/root/environments/environment';
import { AppInsightsConfig, AppInsightsService, SeverityLevel } from '@markpieszak/ng-application-insights';
 

@Injectable({
  providedIn: 'root'
})
export class AppInsightsWrapperService {
  private appInsightsService: AppInsightsService;

  constructor(private injector: Injector) {
    const config =  {
      instrumentationKey: environment.applicationInsightsKey
    };
    this.appInsightsService = new AppInsightsService(config, injector);
  }

  // Wrapper methods for AppInsightsService
  trackEvent(eventName: string, eventProperties?: { [name: string]: string }, metricProperty?: { [name: string]: number }): void {
    this.appInsightsService.trackEvent(eventName, eventProperties, metricProperty);
  }

  startTrackEvent(name: string): any {
    this.appInsightsService.startTrackEvent(name);
  }

  stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
    this.appInsightsService.stopTrackEvent(name, properties, measurements);
  }

  trackPageView(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }, duration?: number): void {
    this.appInsightsService.trackPageView(name, url, properties, measurements, duration);
  }

  startTrackPage(name?: string): void {
    this.appInsightsService.startTrackPage(name);
  }

  stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }): void {
    this.appInsightsService.stopTrackPage(name, url, properties, measurements);
  }

  trackMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number, properties?: { [name: string]: string }): void {
    this.appInsightsService.trackMetric(name, average, sampleCount, min, max, properties);
  }

  trackException(exception: Error, handledAt?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }, severityLevel?: SeverityLevel | AI.SeverityLevel): void {
    this.appInsightsService.trackException(exception, handledAt, properties, measurements, severityLevel);
  }

  trackTrace(message: string, properties?: { [name: string]: string }, severityLevel?: SeverityLevel | AI.SeverityLevel): void {
    this.appInsightsService.trackTrace(message, properties, severityLevel);
  }

  trackDependency(id: string, method: string, absoluteUrl: string, pathName: string, totalTime: number, success: boolean, resultCode: number, properties?: { [name: string]: string }, measurements?: { [name: string]: number }): void {
    this.appInsightsService.trackDependency(id, method, absoluteUrl, pathName, totalTime, success, resultCode, properties, measurements);
  }

  flush(): void {
    this.appInsightsService.flush();
  }

  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {
    this.appInsightsService.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
  }

  clearAuthenticatedUserContext(): void {
    this.appInsightsService.clearAuthenticatedUserContext();
  }

  init(): void {
    this.appInsightsService.init();
  }
}
