import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { AbstractInput } from '../abstract/input';

export const SELECT_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputSelectComponent),
	multi: true
};

export const SELECT_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputSelectComponent),
	multi: true,
};

@Component({
	selector: 'app-input-select',
	templateUrl: 'select.component.html',
	providers: [SELECT_VALUE_ACCESSOR, SELECT_VALUE_VALIDATOR]
})
export class InputSelectComponent extends AbstractInput<string> {
	@Input()
	source: any[];

	@Input()
	itemValue: string = 'id';

	@Input()
	itemLabel: string = 'name';

	@Input()
	emptyHint: string = '';
}
