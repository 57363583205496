<div class="row">
	<div class="col-24">
		<h4 class="text-center mt-4">
			<mark class="bg-light">{{ heading }}</mark>
		</h4>

		<hr />

		<ng-content></ng-content>
	</div>
</div>
