import { Pipe, PipeTransform } from '@angular/core';

// TODO: Extract transform function into Number extension and remove this pipe

@Pipe({
	name: 'appNumber'
})
export class NumberPipe implements PipeTransform {
	public transform(input: any): number {
		if (input) {
			return +(input.toString().replace(/[^0-9\-\.]/ig, ''));
		}

		return input || 0;
	}
}
