import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
	HttpClient,
	HttpParams,
	HttpHeaders,
	HttpParameterCodec,
} from "@angular/common/http";

import { BehaviorSubject, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { ToastrService } from "ngx-toastr";

import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { JwtHelperService } from "@auth0/angular-jwt";

import { PermissionType } from "@frontend/permissions/models";

import { environment } from "@frontend/environment";
import { AppInsightsWrapperService } from "../../shared/services/app-insights-wrapper.service";

export const ACCESS_TOKEN_STORAGE_KEY_NAME =
	environment.authentication.authorize.tokenKeyName;
export const REFRESH_TOKEN_STORAGE_KEY_NAME =
	environment.authentication.refresh.tokenKeyName;

export function getAuthToken() {
	// TODO: Get token from AuthService, check for expiration and refresh if necessary
	return sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY_NAME);
}

@Injectable({ providedIn: "root" })
export class AuthService {
	private authority: string = environment.authentication.authority;
	private idleStart$ = null;
	private idleTimeout$ = null;
	private idleEnd$ = null;

	private idleToastrID = null;

	// TODO: RENAME TO ISAUTHENTICATED & MAKE PUBLIC SO CAN SUBSCRIBE TO IT
	// It is, and should stay, for internal use only, use `changes()` observable
	state = new BehaviorSubject(this.isAuthenticated);

	user = new BehaviorSubject(this.tokenData);

	/**
	 * sessionStorage key name used to store access token
	 */
	public get accessTokenKeyName(): string {
		return ACCESS_TOKEN_STORAGE_KEY_NAME;
	}

	/**
	 * sessionStorage key name used to store refresh token
	 */
	public get refreshTokenKeyName(): string {
		return REFRESH_TOKEN_STORAGE_KEY_NAME;
	}

	/**
	 * Club permissions claim name
	 */
	public get clubPermissionsClaimName(): string {
		return "clubPermissions";
	}

	/**
	 * User permissions claim name
	 */
	public get userPermissionsClaimName(): string {
		return "userPermissions";
	}

	/**
	 * Role claim name
	 */
	public get roleClaimName(): string {
		return "role";
	}

	/**
	 * Stored access token
	 */
	public get token(): string {
		let jwtToken: string = "";
	
		// Get the token asynchronously
		const tokenPromise: Promise<string> | string = this.jwtHelper.tokenGetter();
	
		// If tokenGetter() returns a string immediately, update jwtToken
		if (typeof tokenPromise === "string") {
			jwtToken = tokenPromise;
		} else {
			// If tokenGetter() returns a Promise<string>, update jwtToken when the promise resolves
			if(tokenPromise)
				tokenPromise
				.then((token) => {
					jwtToken = token;
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		}
	
		return jwtToken; // Return the token (initially empty string)
	}

	/**
	 * Stored refresh token
	 */
	public get refreshToken(): string {
		return sessionStorage.getItem(REFRESH_TOKEN_STORAGE_KEY_NAME);
	}

	get tokenData(): any {
		return this.isAuthenticated
			? this.jwtHelper.decodeToken(this.token)
			: null;
	}

	/**
	 * Current authorization status
	 */
	public get isAuthenticated(): boolean {
		const token = this.token;
		const authenticated = token && !this.jwtHelper.isTokenExpired(token);

		// notify subscribers if auth status changes outside of token management
		if (this.state && this.state.value !== authenticated) {
			this.state.next(authenticated);
			this.user.next(this.tokenData);
		}

		return authenticated;
	}

	/**
	 * User's claims list
	 */
	private get claims(): any {
		let claims = [];

		if (this.isAuthenticated) {
			let token = this.token;

			if (token) {
				return JSON.parse(atob(token.split(".")[1]));
			}
		}

		return claims;
	}

	/**
	 * Class constructor
	 */
	public constructor(
		private http: HttpClient,
		private insightsService: AppInsightsWrapperService,
		private jwtHelper: JwtHelperService,
		private idle: Idle,
		private toastr: ToastrService,
		private router: Router
	) {
		if (this.isAuthenticated) {
			this.startIdleTimer();
		}
		this.insightsService.init();
	}

	/**
	 * Authorizes user on the server using provided username and password
	 * @param username Username to authorize with
	 * @param password Password to authorize with
	 */
	public authorize(username: string, password: string) {
		let settings = environment.authentication.authorize;

		let params = new HttpParams({
			encoder: new HttpUrlEncodingCodec(),
			fromObject: {
				grant_type: settings.grantType,
				username: username,
				password: password,
				scope: settings.scope,
				client_id: settings.clientID,
			},
		});

		let options = {
			headers: new HttpHeaders({
				"Content-Type": "application/x-www-form-urlencoded",
			}),
		};

		return this.http
			.post(this.authority + settings.endPoint, params, options)
			.pipe(
				tap((response) => {
					this.saveToken(response);
					this.startIdleTimer();
				})
			);
	}

	/**
	 * Deauthorizes currently authorized user
	 */
	public deauthorize() {
		this.insightsService.clearAuthenticatedUserContext();

		this.deleteToken();
		this.stopIdleTimer();

		return of();
	}

	/**
	 * Refreshes access_token using refresh_token
	 */
	public refresh() {
		let settings = Object.assign(
			{},
			environment.authentication.authorize,
			environment.authentication.refresh
		);

		let params = new HttpParams({
			fromObject: {
				grant_type: settings.grantType,
				scope: settings.scope,
				client_id: settings.clientID,
				refresh_token: this.refreshToken,
			},
		});

		let options = {
			headers: new HttpHeaders({
				"Content-Type": "application/x-www-form-urlencoded",
			}),
		};

		return this.http
			.post(this.authority + settings.endPoint, params, options)
			.pipe(
				tap((response) => {
					this.saveToken(response);
				})
			);
	}

	/**
	 * Returns claim by name
	 */
	public getClaim(name: string): any {
		if (this.isAuthenticated) {
			if (name == this.clubPermissionsClaimName) {
				return JSON.parse(this.claims[this.clubPermissionsClaimName]);
			}

			return this.claims[name] || null;
		}

		return null;
	}

	/**
	 * Checks if user has specific permission
	 */
	public hasUserPermission(type: PermissionType): boolean {
		let permissions = this.getClaim(this.userPermissionsClaimName);

		if (permissions) {
			return permissions.indexOf(type) !== -1;
		}

		return false;
	}

	/**
	 * Checks if user has permission for specific club
	 */
	public hasClubPermission(clubID: number, type: PermissionType): boolean {
		let permissions = this.getClaim(this.clubPermissionsClaimName);

		if (permissions && permissions[clubID]) {
			return permissions[clubID].indexOf(type) !== -1;
		}

		return this.hasUserPermission(type);
	}

	/**
	 * Checks if user has permission for any of their clubs
	 */
	public hasAnyClubPermission(type: PermissionType): boolean {
		let permissions = this.getClaim(this.clubPermissionsClaimName);

		if (permissions) {
			for (let clubId in permissions) {
				if (permissions[clubId].indexOf(type) >= 0) {
					return true;
				}
			}
		}

		return this.hasUserPermission(type);
	}

	/**
	 * Checks if user has permission for any of their clubs
	 */
	public hasRole(...roleNames: string[]): boolean {
		let userRole = <string>this.getClaim(this.roleClaimName);

		if (userRole) {
			for (let roleName of roleNames) {
				if (
					userRole.toUpperCase().trim() ==
					roleName.toUpperCase().trim()
				) {
					return true;
				}
			}
		}

		return false;
	}

	// TODO: REMOVE - SHOULD JUST SUBSCRIBE DIRECTLY TO 'state' (aka authenticated)
	/**
	 * Returns Observable that emits when authentication state changes
	 */
	public changes(): Observable<boolean> {
		return this.state.asObservable();
	}

	/**
	 * Stores access token in sessionStorage
	 * @param response
	 */
	private saveToken(response): void {
		if (response && response.access_token) {
			sessionStorage.setItem(
				this.accessTokenKeyName,
				response.access_token
			);

			this.state.next(this.isAuthenticated);

			if (response.refresh_token) {
				sessionStorage.setItem(
					this.refreshTokenKeyName,
					response.refresh_token
				);
			}

			// set user context on appinsights (use cookie to propagate across all pages)
			this.insightsService.setAuthenticatedUserContext(
				this.tokenData.sub,
				null,
				true
			);
		} else {
			this.insightsService.clearAuthenticatedUserContext();
		}

		this.user.next(this.tokenData);
	}

	/**
	 * Deletes access token stored in sessionStorage
	 */
	private deleteToken(): void {
		sessionStorage.removeItem(this.accessTokenKeyName);

		this.state.next(this.isAuthenticated);
		this.user.next(this.tokenData);
	}

	/**
	 * Start watching for user inactivity and performs logout
	 */
	private startIdleTimer(): void {
		this.idle.setIdle(environment.inactivity.idle);
		this.idle.setTimeout(environment.inactivity.timeout);
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		let timeout = (seconds: number): string => {
			let result = "";

			if (seconds / 60 > 1) {
				result += `${seconds / 60} minute`;
			}

			return result;
		};

		let toastrOptions = {
			disableTimeOut: true,
		};

		if (this.idleToastrID) {
			this.toastr.clear(this.idleToastrID);
		}

		// Performs action when user is inactive for {environment.inactivity.idle} seconds
		this.idleStart$ = this.idle.onIdleStart.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}

			this.idleToastrID = this.toastr.warning(
				`You will be automatically logged out in a couple of minutes due to inactivity.`,
				null,
				toastrOptions
			).toastId;
		});

		// Performs action when user is inactive for {environment.inactivity.idle + environment.inactivity.timeout} seconds
		this.idleTimeout$ = this.idle.onTimeout.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}

			this.idleToastrID = this.toastr.warning(
				`You were automatically logged out due to ${(
					environment.inactivity.idle / 60
				).toFixed(0)} minutes of inactivity.`,
				null,
				toastrOptions
			).toastId;

			this.router.navigateByUrl("/account/sign-out");
		});

		// Performs action when user is back active
		this.idleEnd$ = this.idle.onIdleEnd.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}
		});

		this.idle.watch();
	}

	/**
	 * Stops watching for user inactivity
	 */
	private stopIdleTimer(): void {
		this.idle.stop();
		this.idleStart$.unsubscribe();
		this.idleTimeout$.unsubscribe();
		this.idleEnd$.unsubscribe();
	}
}

export class HttpUrlEncodingCodec implements HttpParameterCodec {
	encodeKey(k: string): string {
		return encodeURIComponent(k);
	}
	encodeValue(v: string): string {
		return encodeURIComponent(v);
	}
	decodeKey(k: string): string {
		return decodeURIComponent(k);
	}
	decodeValue(v: string) {
		return decodeURIComponent(v);
	}
}
