<form #form="ngForm" (input)="onValidate()" (submit)="onSubmit()" novalidate>
	<div class="modal-header">
		<h5 class="modal-title" [innerHTML]="model?.heading"></h5>

		<button type="button" class="btn-close" (click)="onCancel()" aria-label="Close"></button>
	</div>

	<div class="modal-body">
		<div class="form-group">
			<label for="prompt-input" [innerHTML]="model?.message"></label>
			<input class="form-control"
				   id="prompt-input"
				   name="input"
				   [type]="model.input.type || 'text'"
				   [(ngModel)]="input"
				   [required]="true"
				   [mask]="model.input?.textMask"
				   [placeholder]="model.input?.placeholder || ''" />

			<ng-container *ngIf="errors">
				<div class="form-text text-danger" *ngFor="let error of errors | keyvalue">
					<div>{{ error.value }}</div>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn" [ngClass]="'btn-' + model.cancel.type" (click)="onCancel()">
			{{ model.cancel.text }}
		</button>

		<button type="submit" class="btn" [ngClass]="'btn-' + model.submit.type" [disabled]="form.invalid || errors">
			{{ model.submit.text }}
		</button>
	</div>
</form>
