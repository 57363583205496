import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '@frontend/environment';

import { ResourceParameters, ResourceResponse, RenewalYear } from '@frontend/shared/models';

@Injectable({ providedIn: 'root' })
export class RenewalYearsService {
	private urlPrefix: string = environment.rest.apiHost + '/api/lookup';

	renewalYears: BehaviorSubject<RenewalYear[]> = new BehaviorSubject(null);
	selectedYear: BehaviorSubject<RenewalYear> = new BehaviorSubject(null);

	constructor(
		private http: HttpClient
	) {
	}

	init() {
		this.getRenewalYears().subscribe(response => {
			if (response.success) {
				this.renewalYears.next(response.data);
				this.selectedYear.next(response.data.find(ry => ry.current));
			}
		});
	}

	change(): Observable<RenewalYear> {
		return this.selectedYear.pipe(
			distinctUntilChanged((a, b) => {
				return +a.renewalYearID === +b.renewalYearID;
			})
		);
	}

	set(year: RenewalYear) {
		if (year) {
			this.selectedYear.next(year);
		}
	}

	getRenewalYears(params?: ResourceParameters): Observable<ResourceResponse<Array<RenewalYear>>> {
		return this.http.get<ResourceResponse<Array<RenewalYear>>>(this.urlPrefix + '/renewal-years', {
			params: new HttpParams({ fromObject: params })
		});
	}
}
