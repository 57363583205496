<div class="row align-items-center">
	<label class="text-end col-form-label" [ngClass]="labelClass" [class.fw-bold]="strongOn !== null && value === strongOn">
		<ng-container *ngIf="label">
			<span [innerHTML]="label"></span>
		</ng-container>

		<ng-container *ngIf="!label">
			<ng-content></ng-content>
		</ng-container>
	</label>

	<div class="align-self-center" [ngClass]="inputClass">
		<div class="form-check form-check-inline">
			<input class="form-check-input" type="radio" [id]="id + 'Yes'" [name]="name" [(ngModel)]="value" [value]="true" [required]="required" [disabled]="disabled || readonly"  (change)="onChange.emit($event)"/>
			<label class="form-check-label" [for]="id + 'Yes'">Yes</label>
		</div>

		<div class="form-check form-check-inline" [class.is-invalid]="!isValid">
			<input class="form-check-input" type="radio" [id]="id + 'No'" [name]="name" [(ngModel)]="value" [value]="false" [required]="required" [disabled]="disabled || readonly"  (change)="onChange.emit($event)"/>
			<label class="form-check-label" [for]="id + 'No'">No</label>
		</div>
	</div>
</div>
