import { Directive, Input, Output, ViewChild, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModel, ControlValueAccessor, Validator, UntypedFormControl, ValidationErrors } from '@angular/forms';

@Directive()
export abstract class AbstractInput<TModel> implements OnDestroy, ControlValueAccessor, Validator {
	private innerID: string;
	private innerValue: TModel;
	private changed = new Array<(value: TModel) => void>();
	private touched = new Array<() => void>();

	get isValid(): boolean {
		if (this.model.errors) {
			let keys = Object.keys(this.model.errors);

			if (keys.length > 0) {
				return false;
			}
		}

		return true;
	}


	get id() {
		return this.innerID;
	}

	get value(): TModel {
		return this.innerValue;
	}
	set value(value: TModel) {
		if (this.innerValue !== value) {
			this.innerValue = value;
			this.changed.forEach(f => f(value));
		}
	}

	@Input()
	name: string = '';

	@Input()
	label: string = '';

	@Input()
	required: boolean = false;

	@Input()
	disabled: boolean = false;

	@Input()
	readonly: boolean = false;

	@Input()
	placeholder: string = '';

	@Input()
	hint: string = '';

	@Input()
	addon: string = null;

	@Output()
	addonClick: EventEmitter<any> = new EventEmitter<any>();

	@Input()
	labelClass: string = 'col-12';

	@Input()
	inputClass: string = 'col-6';

	@ViewChild(NgModel, { static: true })
	model: NgModel;

	constructor(
		private cdr: ChangeDetectorRef
	) {
	}

	ngOnInit() {
		if (this.name) {
			this.cdr.detectChanges();
			this.innerID = this.name.replace(/[^A-Z0-9]/ig, '_');
		}
	}

	ngOnDestroy() {
		this.value = undefined;
	}

	touch() {
		this.touched.forEach(f => f());
	}

	writeValue(value: TModel) {
		this.innerValue = value;
	}

	registerOnChange(fn: (value: TModel) => void) {
		this.changed.push(fn);
	}

	registerOnTouched(fn: () => void) {
		this.touched.push(fn);
	}

	validate(control: UntypedFormControl): ValidationErrors | null {
		return null;
	}

	onAddonClick(event: any) {
		this.addonClick.emit(event);
	}
}
