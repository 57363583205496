<ng-container *ngIf="review.model && (depends ? (negate ? !review.model[depends] : review.model[depends]) : true)">
	<div class="row py-1" [class.bg-light]="highlight" style="break-inside: avoid;">
		<div [class]="labelClass" [class.fw-bolder]="strong">
			<div [innerHTML]="review.labels[for]"></div>
		</div>

		<div [class]="valueClass">
			<ng-container *ngIf="(innerValue != undefined && innerValue != null) || (type === 'value' || type === 'content'); else empty;">
				<ng-container [ngSwitch]="type">
					<ng-container *ngSwitchCase="'yesno'">
						{{ innerValue ? 'Yes' : 'No' }}
					</ng-container>

					<ng-container *ngSwitchCase="'number'">
						{{ innerValue | number }}
					</ng-container>

					<ng-container *ngSwitchCase="'money'">
						{{ innerValue | currency }}
					</ng-container>

					<ng-container *ngSwitchCase="'percent'">
						{{ (innerValue | appNumber) + '%' }}
					</ng-container>

					<ng-container *ngSwitchCase="'date'">
						{{ innerValue | date:'M/d/yyyy' }}
					</ng-container>

					<ng-container *ngSwitchCase="'datetime'">
						{{ innerValue | date:'M/d/yyyy h:mm a' }}
					</ng-container>

					<ng-container *ngSwitchCase="'lookup'">
						<ng-container *ngIf="lookup?.source && (lookup.source | first:lookup.key:innerValue)">
							{{ (lookup.source | first:lookup.key:innerValue)[lookup.display] }}
						</ng-container>
					</ng-container>

					<ng-container *ngSwitchCase="'value'">
						{{ value }}
					</ng-container>

					<ng-container *ngSwitchCase="'content'">
						<ng-content></ng-content>
					</ng-container>

					<ng-container *ngSwitchDefault>
						{{ innerValue }}
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
</ng-container>

<ng-template #empty>
	<em class="text-muted font-weight-light">&lt;empty&gt;</em>
</ng-template>
