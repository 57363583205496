<ng-container *ngIf="model">
	<div class="row mx-2">
		<div class="col-24">
			<ng-container *ngIf="heading">
				<h3 class="text-center mx-auto my-3">
					{{ heading }}
				</h3>
			</ng-container>

			<ng-content></ng-content>
		</div>
	</div>
</ng-container>
