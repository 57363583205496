<ng-container *ngIf="model">
	<span class="badge rounded-pill" [ngClass]="[badgeColor]">
		<ng-container *ngIf="type === 'none'">
			{{ model.total }}
		</ng-container>

		<ng-container *ngIf="type === 'single'">
			<ng-container *ngIf="reviewable">
				<ng-container [ngSwitch]="model.status">
					<ng-container *ngSwitchCase="'not-started'">
						<span [tooltip]="'# of items in the \'Not Started\' state'">
							<fa-icon [icon]="['far', 'circle']"></fa-icon>
						</span>
					</ng-container>

					<ng-container *ngSwitchCase="'draft'">
						<span [tooltip]="'# of items in the \'Draft\' state'">
							<fa-icon [icon]="['fas', 'adjust']"></fa-icon>
						</span>
					</ng-container>

					<ng-container *ngSwitchCase="'submitted'">
						<span [tooltip]="'# of items in the \'Submitted\' state'">
							<fa-icon [icon]="['fas', 'circle']"></fa-icon>
						</span>
					</ng-container>

					<ng-container *ngSwitchCase="'rejected'">
						<span [tooltip]="'# of items in the \'Rejected\' state'">
							<fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
						</span>
					</ng-container>

					<ng-container *ngSwitchCase="'approved'">
						<span [tooltip]="'# of items in the \'Approved\' state'">
							<fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
						</span>
					</ng-container>

					<ng-container *ngSwitchDefault>
						{{ model.total }}
					</ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="!reviewable">
				{{ model.total }}
			</ng-container>
		</ng-container>


		<ng-container *ngIf="type === 'multi'">
			<ng-container *ngIf="reviewable">
				<ng-container *ngIf="model.approved === model.total">
					<span [tooltip]="'Approved ' + model.total + ' out of ' + model.total + ' started'">
						{{ model.total }}
					</span>
				</ng-container>

				<ng-container *ngIf="model.approved !== model.total">
					<span [tooltip]="'Submitted ' + (model.submitted + model.approved + model.rejected) + ' out of ' + model.total + ' started'">
						{{ model.submitted + model.approved + model.rejected }} / {{ model.total }}
					</span>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="!reviewable">
				{{ model.total }}
			</ng-container>
		</ng-container>
	</span>
</ng-container>
