import { AuthGuard } from './guards/auth.guard';
import { ClubPermissionGuard } from './guards/club-permission.guard';
import { UserPermissionGuard } from './guards/user-permission.guard';
import { AnyClubPermissionGuard } from './guards/any-club-permission.guard';

export { AuthGuard } from './guards/auth.guard';
export { ClubPermissionGuard } from './guards/club-permission.guard';
export { UserPermissionGuard } from './guards/user-permission.guard';
export { AnyClubPermissionGuard } from './guards/any-club-permission.guard';

export const GUARDS: any[] = [
	AuthGuard,
	ClubPermissionGuard,
	UserPermissionGuard,
	AnyClubPermissionGuard
];
