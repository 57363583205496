import { ClubsService } from './services/clubs.service';

export { ClubsService } from './services/clubs.service';

// TODO: Replace code below when new feature comes out
// Workaround until JwtModule.forChild() comes
// See: https://github.com/auth0/angular2-jwt/issues/440
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@auth0/angular-jwt';
export const JwtInterceptorForChild = {
	provide: HTTP_INTERCEPTORS,
	useClass: JwtInterceptor,
	multi: true
};

export const SERVICES: any[] = [
	ClubsService,
	JwtInterceptorForChild
];
