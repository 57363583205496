<div class="row flex-nowrap mb-3">
	<label class="col-form-label text-end" [ngClass]="labelClass" [for]="id">
		<ng-container *ngIf="label">
			{{ label }}
		</ng-container>

		<ng-container *ngIf="!label">
			<ng-content></ng-content>
		</ng-container>
	</label>

	<div [ngClass]="inputClass">
		<div class="input-group align-self-center">
			<input type="date"
				   class="form-control text-mask"
				   [(ngModel)]="value"
				   [id]="id"
				   [name]="name"
				   [min]="min"
				   [max]="max"
				   [placeholder]="placeholder"
				   [required]="required"
				   [readonly]="readonly"
				   [disabled]="disabled" />

			<ng-container *ngIf="addon">
				<button type="button" class="btn btn-outline-default" (click)="onAddonClick($event)">
					{{ addon }}
				</button>
			</ng-container>
		</div>

		<div *ngIf="hint" class="form-text text-muted" [innerHTML]="hint"></div>
	</div>
</div>
