import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MetadataService {
	private prefix: string = 'RMIS';
	private delimiter: string = ' - ';

	private segments: string[] = [];

	public constructor(
		private title: Title
	) {
	}

	public getTitle(): string {
		return this.title.getTitle();
	}

	public setTitle(...segments: string[]): void {
		this.segments = [this.prefix];

		segments.forEach(s => this.segments.push(s));

		this.title.setTitle(this.segments.join(this.delimiter));
	}
}
