export class ResponseError {
	code: string;
	description: string;
}

export class ResourceResponse<TModel> {
	public success: boolean;
	public data: TModel;

	public links?: string[];
	public messages?: string[];
	public errors?: ResponseError[];
}
