<div class="modal-header">
	<h5 class="modal-title" [innerHTML]="heading"></h5>

	<button type="button" class="btn-close" (click)="onSubmit()" aria-label="Close"></button>
</div>

<div class="modal-body">
	<div class="form-group" [innerHTML]="message">
	</div>
</div>

<div class="modal-footer">
	<button type="submit" class="btn" [ngClass]="'btn-' + this.model.submit.type" (click)="onSubmit()">
		{{ this.model.submit.text }}
	</button>
</div>
