<ng-container *ngIf="canCopy()">
	<accordion [isAnimated]="true">
		<accordion-group heading="Copy From Previous Years">
			<div class="card-body p-4">
				<div class="row mb-3">
					<div class="col-auto me-auto">
						<select class="form-select" [(ngModel)]="renewalYearID" (change)="onReload()" [disabled]="disabled">
							<ng-container *ngFor="let renewalYear of renewalYears">
								<option *ngIf="renewalYear.renewalYearID != selectedYearID" [value]="renewalYear.renewalYearID">
									{{ renewalYear.year }}
								</option>
							</ng-container>
						</select>
					</div>

					<div class="col-auto ms-auto">
						<button type="button" class="btn btn-outline-primary" (click)="onCopy()" [disabled]="disabled || selection?.length == 0">
							<ng-container *ngIf="copying === false">
								Copy Selected {{ entityName }}
							</ng-container>

							<ng-container *ngIf="copying === true">
								Copying Selected {{ entityName }}
								<div class="spinner-border spinner-border-sm"></div>
							</ng-container>
						</button>
					</div>
				</div>

				<div class="row">
					<div class="col-24">
						<ng-container *ngIf="!loading">
							<ng-content></ng-content>
						</ng-container>

						<ng-container *ngIf="loading">
							<app-spinner message="loading data..."></app-spinner>
						</ng-container>
					</div>
				</div>
			</div>
		</accordion-group>
	</accordion>
</ng-container>
