<div class="row mb-3">
	<label class="col-form-label text-end" [ngClass]="labelClass" [for]="id">
		<ng-container *ngIf="label">
			<span [innerHTML]="label"></span>
		</ng-container>

		<ng-container *ngIf="!label">
			<ng-content></ng-content>
		</ng-container>
	</label>

	<div class="align-self-center" [ngClass]="inputClass">
		<input type="text"
			   class="form-control text-mask text-end"
			   currencyMask
			   [id]="id"
			   [name]="name"
			   [(ngModel)]="inner"
			   [placeholder]="placeholder"
			   [required]="required"
			   [readonly]="readonly"
			   [disabled]="disabled"
			   [options]="{ prefix: '', suffix: '%', precision: 0 }"
			   [min]="min"
			   [max]="max"
			   [class.is-invalid]="!isValid" />

		<div *ngIf="hint" class="form-text text-muted" [innerHTML]="hint"></div>
	</div>
</div>
