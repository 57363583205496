import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@frontend/environment';

import { GlobalState } from '@frontend/shared/models';
import { ResourceResponse, ResourceParameters } from '@frontend/shared/models';

@Injectable({ providedIn: 'root' })
export class StateService {
	protected urlPrefix: string = environment.rest.apiHost + '/api/lookup';

	globalState = new BehaviorSubject<GlobalState>(null);

	constructor(
		private http: HttpClient
	) {
	}

	getGlobalState(params?: ResourceParameters): Observable<ResourceResponse<GlobalState>> {
		return this.http.get<ResourceResponse<GlobalState>>(this.urlPrefix + '/state', {
			params: new HttpParams({ fromObject: Object.assign({}, params) })
		});
	}

	refresh() {
		this.http
			.get<ResourceResponse<GlobalState>>(environment.rest.apiHost + '/api/lookup/state')
			.subscribe(response => {
				if (response.success) {
					this.globalState.next(response.data);
				}
			});
	}
}
