import { Component, Input, OnInit } from '@angular/core';

import { LookupService } from '@frontend/core/services';
import { Country, State } from '@frontend/core/models';

import { TextMask } from '@frontend/shared/constants';

@Component({
	selector: 'app-address',
	templateUrl: 'address.component.html'
})
export class AddressComponent implements OnInit {
	countries: Country[];
	states: State[];

	countryStates: State[];

	zipMask = TextMask.ZipMask;

	@Input()
	public model: any;

	@Input()
	public labelClass: string = 'col-8';

	@Input()
	public inputClass: string = 'col-16';

	@Input()
	public showName: boolean = true;

	@Input()
	public required: boolean = true;

	@Input()
	public requireAddress: boolean = false;

	@Input()
	public requireCity: boolean = false;

	@Input()
	public requireZipCode: boolean = false;

	@Input()
	public readonly: boolean = false;

	@Input()
	public showLongLabel: boolean = false;
	
	constructor(
		private lookupService: LookupService
	) { }

	ngOnInit() {
		this.lookupService.getCountries().subscribe(response => {
			if (response.success) {
				this.countries = response.data;

				this.loadStatesForCountry();
			}
		});

		this.lookupService.getStates().subscribe(response => {
			if (response.success) {
				this.states = response.data;

				this.loadStatesForCountry();
			}
		});
	}

	loadStatesForCountry() {
		if (this.states && this.model) {
			this.countryStates = this.states
				.filter(s => s.countryID == this.model.countryID);

			if (this.countryStates.some(s => s.stateID === this.model.stateID) === false) {
				this.model.stateID = null;
			}
		}
	}

	getZipMask(value: string) {
		if (this.model && this.model.countryID == 1) {
			if (value) {
				let digits = value.replace(/[^0-9]/ig, '');

				if (digits.length > 5) {
					return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
				}
			}

			return [/\d/, /\d/, /\d/, /\d/, /\d/];
		}

		return false;
	}
}
