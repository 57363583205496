import { Directive, OnInit, OnDestroy } from "@angular/core";
import { NgModel } from "@angular/forms";

import { Subscription } from "rxjs";

@Directive({
	selector: '[noWhiteSpaces]'
})
export class NoWhiteSpacesDirective implements OnInit, OnDestroy {
	private valueChanges$: Subscription;

	constructor(
		private model: NgModel
	) {
	}

	ngOnInit() {
		this.valueChanges$ = this.model.valueChanges.subscribe(value => {
			if (value && value.length > 0 && value.trim().length === 0) {
				this.model.control.setErrors({ 'whitespaces': true });
			}
		});
	}

	ngOnDestroy() {
		this.valueChanges$.unsubscribe();
	}
}
