<div #top></div>
<ng-container *ngIf="steps">
	<aw-wizard #wizard class="stepper" [defaultStepIndex]="0" [navBarLocation]="'top'" [navBarLayout]="'large-empty-symbols'" [awNavigationMode] [navigateForward]="'allow'" [navigateBackward]="'allow'">
		<ng-container *ngFor="let step of steps; let i = index;">
			<aw-wizard-step [stepTitle]="step.title" [navigationSymbol]="{ symbol: i + 1 }" [canEnter]="true" [canExit]="true" (stepEnter)="onStepEnter(step)">
				<div class="step-content">
					<ng-container *ngTemplateOutlet="step.template"></ng-container>
				</div>
			</aw-wizard-step>
		</ng-container>
	</aw-wizard>

	<ng-container *ngTemplateOutlet="actions.template"></ng-container>
</ng-container>
