export class VehicleLessor {
	vehicleLessorID: number;
	clubID: number;
	vehicleLessorName: string;
	address1: string;
	address2: string;
	countryID: number;
	city: string;
	stateID: number;
	zip: string;
}
