export class ApplicationBase {
	// Club related fields
	clubID: number;
	renewalYearID: number;

	// Read-only fields
	approved: boolean;
	digitalSignature: string;

	isDraft: boolean = true;
	isFinal: boolean = false;

	// UI action fields
	unsubmitting: boolean = false;
	approving: boolean = false;
	rejecting: boolean = false;
	deleting: boolean = false;
}
