import { Component, Input, ContentChild } from '@angular/core';

import { PageListToolbarComponent } from './page-list-toolbar.component';
import { PageListActionComponent } from './page-list-action.component';
import { PageListCopyComponent } from './page-list-copy.component';

@Component({
	selector: 'app-page-list',
	templateUrl: 'page-list.component.html',
	styleUrls: ['page-list.component.scss']
})
export class PageListComponent {
	@Input()
	public source: any[];

	@Input()
	public heading: string = 'Untitled List';

	@Input()
	public showCount: boolean = true;

	@Input()
	public showLoader: boolean = true;

	@Input()
	public loadingMessage: string = 'loading data...';

	@Input()
	public submittedDate: Date = null;

	@Input()
	public class: string = '';

	@ContentChild(PageListToolbarComponent, { static: true })
	public toolbar: PageListToolbarComponent;

	@ContentChild(PageListActionComponent, { static: true })
	public action: PageListActionComponent;

	@ContentChild(PageListCopyComponent, { static: true })
	public copy: PageListCopyComponent;
}
