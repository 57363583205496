import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
	public transform(collection: Array<any>, property: string): Array<any> {
		if (!collection) {
			return null;
		}

		let grouped = collection.reduce((previous, current) => {
			if (!previous[current[property]]) {
				previous[current[property]] = [current];
			} else {
				previous[current[property]].push(current);
			}

			return previous;
		}, {});

		return Object.keys(grouped).map(key => ({
			key,
			value: grouped[key]
		}));
	}
}
