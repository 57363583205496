<header class="header" *ngIf="user && renewalYears">
	<nav class="navbar navbar-expand-lg bg-white">
		<div class="container-fluid">
			<a class="d-flex align-content-center navbar-brand" routerLink="/">
				<img class="my-1" src="/assets/images/mlb-rmis-logo5.svg" height="24" alt="MLB RMIS LOGO" />
				<span *ngIf="isTesting" class="badge text-bg-warning my-auto ms-2">TEST</span>
			</a>

			<button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#header-menu">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div id="header-menu" class="collapse navbar-collapse">
				<div class="navbar-nav mx-auto">
					<ng-container *ngIf="!club">
						<a class="nav-item nav-link" *hasUserPermission="'Home_View'" routerLink="/clubs" routerLinkActive="active">Clubs</a>
					</ng-container>

					<ng-container *ngIf="club">
						<a class="nav-item nav-link" [routerLink]="['/clubs', club.clubID]" routerLinkActive="active">
							{{ club.clubName }}
						</a>
					</ng-container>

					<a class="nav-item nav-link" *hasUserPermission="'Approvals_View'" routerLink="/approvals" routerLinkActive="active">
						<span>Approvals</span>
						<span class="badge rounded-pill text-bg-warning ms-2 px-2 py-1" *ngIf="(globalState | async)">{{ (globalState | async)?.pending }}</span>
					</a>

					<a class="nav-item nav-link" *hasAnyClubPermission="'Reports_View'" routerLink="/reports" routerLinkActive="active">Reports</a>
					<a class="nav-item nav-link" *hasUserPermission="'Maintenance_View'" routerLink="/maintenance" routerLinkActive="active">Configuration</a>
					<a class="nav-item nav-link" *hasUserPermission="'Resources_View'" routerLink="/resources" routerLinkActive="active">Resources</a>
					<a class="nav-item nav-link" *hasUserPermission="'Profile_View'" routerLink="/profile" routerLinkActive="active">Profile</a>
				</div>
			</div>

			<div class="navbar-nav align-items-center ms-auto">
				<div class="px-3">
					<select class="form-select renewal-year-select" [(ngModel)]="renewalYearID" (change)="onRenewalYearChange()" [ngClass]="{ 'bg-warning': renewalYear?.current == false }">
						<option class="bg-white" *ngFor="let entry of renewalYears" [value]="entry.renewalYearID">
							{{ entry.year }}
						</option>
					</select>
				</div>

				<a class="nav-item nav-link" routerLink="/account/sign-out">
					SIGN OUT <span *ngIf="(user | async)">({{ (user | async)?.given_name[0] }}{{ (user | async)?.family_name[0] }})</span>
				</a>
			</div>
		</div>
	</nav>
</header>
