import { Component, AfterViewInit, ViewChild, ContentChildren, QueryList, ContentChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';

import { WizardComponent } from '@y3krulez/angular-archwizard';

import { PageWizardStepComponent } from './page-wizard-step.component';
import { PageWizardActionsComponent } from './page-wizard-actions.component';

@Component({
	selector: 'app-page-wizard',
	templateUrl: 'page-wizard.component.html',
	styleUrls: ['page-wizard.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PageWizardComponent implements AfterViewInit {
	@ViewChild('wizard', { static: false })
	wizard: WizardComponent;

	@ViewChild('top', { static: true })
	top: ElementRef;

	@ContentChildren(PageWizardStepComponent)
	steps: QueryList<PageWizardStepComponent>;

	@ContentChild(PageWizardActionsComponent, { static: true })
	actions: PageWizardActionsComponent;

	constructor(
		private cdr: ChangeDetectorRef
	) {
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	onStepEnter(step: PageWizardStepComponent) {
		this.top.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}

	hasPrevStep() {
		if (this.wizard) {
			this.cdr.detectChanges();

			return this.wizard.hasPreviousStep();
		}

		return false;
	}

	prev() {
		this.wizard.goToPreviousStep();
	}

	hasNextStep() {
		if (this.wizard) {
			this.cdr.detectChanges();

			return this.wizard.hasNextStep();
		}

		return false;
	}

	next() {
		this.wizard.goToNextStep();
	}
}
