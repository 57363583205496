import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-review-group',
	templateUrl: 'review-group.component.html'
})
export class ReviewGroupComponent implements OnInit {
	@Input()
	heading: string = '';

	@Input()
	highlight: boolean = false;

	constructor() {
	}

	ngOnInit() {
	}
}
