import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ConfirmComponent } from '@frontend/core/components';

@Injectable({ providedIn: 'root' })
export class ConfirmService {
	private modalRef: BsModalRef;

	public constructor(
		private modalService: BsModalService
	) {
	}

	public show(options: PromptOptions, actions?: any): Observable<boolean> {
		let result = new Subject<boolean>();

		this.modalRef = this.modalService.show(ConfirmComponent, {
			ignoreBackdropClick: true,
			initialState: {
				model: options
			}
		});

		this.modalService.onHide.subscribe(response => {
			if (response == 'backdrop-click') {
				result.next(false);
			} else {
				result.next(this.modalRef.content.result || false);
			}

			result.complete();
		});

		return result.asObservable();
	}

	public hide() {
		this.modalRef.hide();
	}
}
