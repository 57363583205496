import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { ConfirmService } from '@frontend/core/services';

export interface CanDeactivateForm {
	form: NgForm
}

@Injectable({ providedIn: 'root' })
export class CanDeactivateFormGuard implements CanDeactivate<CanDeactivateForm> {
	constructor(
		private confirm: ConfirmService
	) {
	}

	public canDeactivate(component: CanDeactivateForm): Observable<boolean> {
		let subject = new Subject<boolean>();

		if (component.form && component.form.dirty) {
			let options: PromptOptions = {
				heading: 'Unsaved Changes',
				message: 'Would you like to leave this form and discard all unsaved changes?',
				submit: {
					text: 'Discard Changes',
					type: 'warning'
				},
				cancel: {
					text: 'Keep Editing',
					type: 'primary'
				}
			};

			this.confirm.show(options).subscribe(result => {
				subject.next(result);
			});
		} else {
			setTimeout(() => {
				subject.next(true);
			}, 0);
		}

		return subject.asObservable();
	}
}
