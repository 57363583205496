import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'confirm.component.html'
})
export class ConfirmComponent implements OnInit {
	public model: PromptOptions;

	public result: boolean = false;

	public constructor(
		private modalRef: BsModalRef
	) { }

	public ngOnInit() {
		if (!this.model.submit) {
			this.model.submit = {
				text: 'Submit',
				type: 'primary'
			};
		}

		if (!this.model.cancel) {
			this.model.submit = {
				text: 'Cancel',
				type: 'link'
			};
		}
	}

	public onSubmit() {
		this.result = true;
		this.modalRef.hide();
	}

	public onCancel() {
		this.result = false;
		this.modalRef.hide();
	}
}
