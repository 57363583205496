import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { RenewalYearsService } from '@frontend/shared/services';
import { RenewalYear } from '@frontend/shared/models';

@Component({
	selector: 'app-page-copy',
	templateUrl: 'page-list-copy.component.html',
	styleUrls: ['page-list-copy.component.scss']
})
export class PageListCopyComponent implements OnInit {
	renewalYears: RenewalYear[];
	renewalYearID: number;

	selectedYear: RenewalYear;
	selectedYearID: number;

	get renewalYear(): RenewalYear {
		if (this.renewalYears) {
			return this.renewalYears.find(ry => ry.renewalYearID == this.renewalYearID);
		}

		return null;
	};

	@Output()
	reload: EventEmitter<number> = new EventEmitter<number>();

	@Input()
	selection: any[];

	@Output()
	copy: EventEmitter<number[]> = new EventEmitter<number[]>();

	@Input()
	disabled: boolean = false;

	@Input()
	copying: boolean = false;

	@Input()
	loading: boolean = false;

	@Input()
	entityName: string = '';

	constructor(
		public renewalYearsService: RenewalYearsService
	) { }

	ngOnInit(): void {
		if (!this.selection) {
			this.selection = [];
		}

		this.renewalYearsService.selectedYear.subscribe(year => {
			this.selectedYear = year;
			this.selectedYearID = year.renewalYearID;

			if (this.renewalYears) {
				this.renewalYearID = this.renewalYears
					.filter(ry => ry.renewalYearID != this.selectedYearID)[0]
					.renewalYearID;

				this.onReload();
			}
		});

		this.renewalYearsService.getRenewalYears().subscribe(response => {
			if (response.success) {
				this.renewalYears = response.data;
				this.renewalYearID = this.renewalYears
					.filter(ry => ry.renewalYearID != this.selectedYearID)[0]
					.renewalYearID;

				this.onReload();
			}
		});
	}

	onReload() {
		if (this.renewalYearID) {
			this.reload.emit(this.renewalYearID);
		}
	}

	canCopy(): boolean {
		if (this.renewalYears) {
			let currentRenewalYear = this.renewalYears.find(ry => ry.current);

			if (currentRenewalYear && this.selectedYear) {
				return this.selectedYear.year >= currentRenewalYear.year;
			}
		}

		return false;
	}

	onCopy() {
		this.copy.emit(this.selection);
		this.selection = [];
	}
}
