import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ResourceParameters, ResourceResponse, FileResponse } from '@frontend/shared/models';

import { environment } from '@frontend/environment';

import { StateService } from './state.service';

@Injectable()
export abstract class RestService<TModel = any> {
	private params: { [key: string]: any } = {};
	private urlParams: { [key: string]: any } = {};
	private apiHost: string = environment.rest.apiHost;

	protected abstract urlPrefix: string;

	public constructor(
		protected http: HttpClient,
		protected state: StateService
	) {
	}

	protected getUrl(...suffix: Array<string | number>): string {
		let url = this.apiHost + this.urlPrefix;

		if (suffix) {
			let append = suffix
				// NOTE: .filter LINE FILTERS OUT 0s (zeros), WHICH ARE VALID SUFFIXES
				// .filter(segment => segment)
				.filter(segment => segment || segment === 0)
				.map(segment => segment.toString())
				.join('/')
				.replace(/\/$/, '');

			if (append) {
				url += '/' + append;
			}
		}

		if (this.urlParams) {
			for (let key of Object.keys(this.urlParams)) {
				let token = '{' + key + '}';

				if (url.indexOf(token) !== -1) {
					url = url.replace(token, this.urlParams[key]);
				}
			}
		}

		return url;
	}

	protected getFile(url: string, params?: ResourceParameters): Observable<FileResponse> {
		let subject = new Subject<FileResponse>();

		let options: Object = {
			observe: 'response',
			responseType: 'ArrayBuffer',
			params: this.getParams(params)
		};

		this.http.get<any>(url, options).subscribe(
			(response) => {
				let contentDisposition = response.headers.get('Content-Disposition');
				let contentType = response.headers.get('Content-Type');
				let contentBody = new Blob([response.body], { type: contentType });

				let fileName = contentDisposition
					.split(';')[1]
					.trim()
					.split('=')[1]
					.replace(/"/g, '');

				let model = <FileResponse>{
					name: fileName,
					type: contentType,
					blob: contentBody
				};

				subject.next(model);
			},

			(error) => {
				subject.error(error);
			}
		);

		return subject.asObservable();
	}

	public getParams(params: { [key: string]: any } = {}): HttpParams {
		return new HttpParams({ fromObject: Object.assign(this.params, params) });
	}

	public setParams(params: { [key: string]: any }): RestService<TModel> {
		this.params = Object.assign({}, this.params, params);

		return this;
	}

	public setUrlParams(params: { [key: string]: any }): RestService<TModel> {
		this.urlParams = Object.assign({}, this.urlParams, params);

		return this;
	}

	public list<TReturn = TModel>(params?: ResourceParameters): Observable<ResourceResponse<Array<TReturn>>> {
		return this.http.get<ResourceResponse<Array<TReturn>>>(this.getUrl(), {
			params: this.getParams(params)
		});
	}

	public get<TReturn = TModel>(id: string | number, params?: ResourceParameters): Observable<ResourceResponse<TReturn>> {
		return this.http.get<ResourceResponse<TReturn>>(this.getUrl(id), {
			params: this.getParams(params)
		});
	}

	public create<TReturn = TModel>(model: TModel, params?: ResourceParameters): Observable<ResourceResponse<TReturn>> {
		return this.http.post<ResourceResponse<TReturn>>(this.getUrl(), model, {
			params: this.getParams(params)
		}).pipe(
			tap(response => this.state.refresh())
		);
	}

	public update<TReturn = TModel>(id: string | number, model: TModel, params?: ResourceParameters): Observable<ResourceResponse<TReturn>> {
		return this.http.put<ResourceResponse<TReturn>>(this.getUrl(id), model, {
			params: this.getParams(params)
		}).pipe(
			tap(response => this.state.refresh())
		);
	}

	public delete<TReturn = TModel>(id: string | number, params?: ResourceParameters): Observable<ResourceResponse<TReturn>> {
		return this.http.delete<ResourceResponse<TReturn>>(this.getUrl(id), {
			params: this.getParams(params)
		}).pipe(
			tap(response => this.state.refresh())
		);
	}
}
