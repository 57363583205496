import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
	public transform(size: number): string {
		if (size / 1024 / 1024 / 1024 >= 1) {
			return (size / 1024 / 1024 / 1024).toFixed(2) + ' Gb';
		}

		if (size / 1024 / 1024 >= 1) {
			return (size / 1024 / 1024).toFixed(2) + ' Mb';
		}

		if (size / 1024 >= 1) {
			return (size / 1024).toFixed(2) + ' Kb';
		}

		return size.toFixed(2) + ' b';
	}
}
