import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RenewalYearsService } from '@frontend/shared/services/renewal-years.service';

@Injectable()
export class RenewalYearsInterceptor implements HttpInterceptor {
	constructor(
		private renewalYearService: RenewalYearsService
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let url = request.url.toLowerCase();

		if (url.indexOf('/api/lookup/renewal-years') === -1) {
			if (this.renewalYearService && this.renewalYearService.selectedYear && this.renewalYearService.selectedYear.value) {
				let renewalYear = this.renewalYearService.selectedYear.value;

				if (renewalYear) {
					return next.handle(
						request.clone({
							setHeaders: {
								'RenewalYearID': renewalYear.renewalYearID.toString()
							}
						})
					);
				}
			}
		}

		return next.handle(request);
	}
}

export const RenewalYearsInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: RenewalYearsInterceptor,
	multi: true
};
