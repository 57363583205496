import { Component, ViewChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-page-wizard-actions',
	templateUrl: 'page-wizard-actions.component.html',
	styleUrls: ['page-wizard-actions.component.scss']
})
export class PageWizardActionsComponent {
	@ViewChild(TemplateRef, { static: true })
	template: TemplateRef<any>;
}
