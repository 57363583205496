import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RestService } from '@frontend/shared/services';
import { ResourceParameters, ResourceResponse } from '@frontend/shared/models';

import * as core from '@frontend/core/models';

@Injectable({ providedIn: 'root' })
export class LookupService extends RestService<any> {
	protected urlPrefix: string = '/api/lookup';

	getCountries(params?: ResourceParameters): Observable<ResourceResponse<Array<core.Country>>> {
		return this.http.get<ResourceResponse<Array<core.Country>>>(this.getUrl('countries'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getStates(params?: ResourceParameters): Observable<ResourceResponse<Array<core.State>>> {
		return this.http.get<ResourceResponse<Array<core.State>>>(this.getUrl('states'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getStatesUS(params?: ResourceParameters): Observable<ResourceResponse<Array<core.State>>> {
		return this.http.get<ResourceResponse<Array<core.State>>>(this.getUrl('states/us'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getPremiumPayers(params?: ResourceParameters): Observable<ResourceResponse<Array<core.PremiumPayer>>> {
		return this.http.get<ResourceResponse<Array<core.PremiumPayer>>>(this.getUrl('premium-payers'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getPlanTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.PlanType>>> {
		return this.http.get<ResourceResponse<Array<core.PlanType>>>(this.getUrl('plan-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getPlanStatuses(params?: ResourceParameters): Observable<ResourceResponse<Array<core.PlanStatus>>> {
		return this.http.get<ResourceResponse<Array<core.PlanStatus>>>(this.getUrl('plan-statuses'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getAffiliationTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.AffiliationType>>> {
		return this.http.get<ResourceResponse<Array<core.AffiliationType>>>(this.getUrl('affiliation-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getLocationOwnershipTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.LocationOwnershipType>>> {
		return this.http.get<ResourceResponse<Array<core.LocationOwnershipType>>>(this.getUrl('location-ownership-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getVehicleTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.VehicleType>>> {
		return this.http.get<ResourceResponse<Array<core.VehicleType>>>(this.getUrl('vehicle-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getVehicleOwnershipTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.VehicleOwnershipType>>> {
		return this.http.get<ResourceResponse<Array<core.VehicleOwnershipType>>>(this.getUrl('vehicle-ownership-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getVehicleUsageTypes(params?: ResourceParameters): Observable<ResourceResponse<core.VehicleUsageType[]>> {
		return this.http.get<ResourceResponse<core.VehicleUsageType[]>>(this.getUrl('vehicle-usage-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getEntityTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.EntityType>>> {
		return this.http.get<ResourceResponse<Array<core.EntityType>>>(this.getUrl('entity-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getDocumentTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.DocumentType>>> {
		return this.http.get<ResourceResponse<Array<core.DocumentType>>>(this.getUrl('document-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getEventTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.EventType>>> {
		return this.http.get<ResourceResponse<Array<core.EventType>>>(this.getUrl('event-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getEventPromotionLevels(params?: ResourceParameters): Observable<ResourceResponse<Array<core.EventPromotionLevel>>> {
		return this.http.get<ResourceResponse<Array<core.EventPromotionLevel>>>(this.getUrl('event-promotion-levels'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getVirtualEventStatuses(params?: ResourceParameters): Observable<ResourceResponse<Array<core.VirtualEventStatus>>> {
		return this.http.get<ResourceResponse<Array<core.VirtualEventStatus>>>(this.getUrl('virtual-event-statuses'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getClubLocations(clubID:number, params?: ResourceParameters): Observable<ResourceResponse<Array<core.ClubLocations>>> {
		return this.http.get<ResourceResponse<Array<core.ClubLocations>>>(this.getUrl('club-locations', clubID), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getTrooleans(params?: ResourceParameters): Observable<ResourceResponse<Array<core.Troolean>>> {
		return this.http.get<ResourceResponse<Array<core.Troolean>>>(this.getUrl('trooleans'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getSignatureTerms(applicationTypeID: number, params?: ResourceParameters): Observable<ResourceResponse<string>> {
		return this.http.get<ResourceResponse<string>>(this.getUrl('signature-terms', applicationTypeID), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getClubTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.ClubType>>> {
		return this.http.get<ResourceResponse<Array<core.ClubType>>>(this.getUrl('club-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getHousingTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.HousingType>>> {
		return this.http.get<ResourceResponse<Array<core.HousingType>>>(this.getUrl('housing-types'), {
			params: new HttpParams({ fromObject: params })
		});
	}

	getApplicationTypes(params?: ResourceParameters): Observable<ResourceResponse<Array<core.ApplicationType>>> {
		let sortedApplicationTypes = [
			'HousingLocations',
			'Locations',
			'NamedInsured',
			'Vehicles',
			'Values',
			'Headcount',
			'Payroll',
			'Events',
			'Crime',
			'Foreign',
			'Media',
			'Fiduciary',
			'Pollution',
		];

		return this.http.get<ResourceResponse<Array<core.ApplicationType>>>(this.getUrl('application-types'), {
			params: new HttpParams({ fromObject: params })
		}).pipe(
			tap(response => {
				if (response.success) {
					response.data = response.data.sort((a, b) => {
						let aIndex = sortedApplicationTypes.indexOf(a.constantName);
						let bIndex = sortedApplicationTypes.indexOf(b.constantName);

						if (aIndex > bIndex) {
							return 1;
						}

						if (aIndex < bIndex) {
							return -1;
						}

						return 0;
					});
				}

				return response;
			})
		);
	}

	getOccupations(params?: ResourceParameters): Observable<ResourceResponse<Array<core.Occupation>>> {
		return this.http.get<ResourceResponse<Array<core.Occupation>>>(this.getUrl('occupations'), {
			params: new HttpParams({ fromObject: params })
		});
	}
}
