import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'first'
})
export class FirstPipe implements PipeTransform {
	public transform(source: any[], property: string, value: any) {
		if (source) {
			// tslint:disable-next-line:triple-equals
			return source.find(entry => entry[property] == value);
		}

		return null;
	}
}
