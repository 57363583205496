import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '@frontend/core/services';
import { UpdateComponent } from '@frontend/core/components';

import * as version from '../version.json';
import { AppInsightsWrapperService } from './shared/services/app-insights-wrapper.service';
import { filter, map, switchMap } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	private build: string = version.default.build;
	 private updateAvailable = false;
	isAuthenticated = this.authService.state;

	constructor(
		private appInsights: AppInsightsWrapperService,
		private authService: AuthService,
		private modalService: BsModalService,
		private updates: SwUpdate,
		private router: Router
	) {
		this.appInsights.init();

		// TODO: REMOVE ONCE FINISHED TESTING
		this.appInsights.trackEvent('app-access');
	}

	ngOnInit() {
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				window.scrollTo(0, 0);
			}
		});

		document.body.setAttribute('build', this.build);

		this.updates.versionUpdates
		.pipe(
		  filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
		  switchMap(() => {
			const modalRef = this.modalService.show(UpdateComponent, {
			  ignoreBackdropClick: true
			});
	  
			// Ensure the modal has an onClose observable
			return modalRef.content.onClose.pipe(
			  switchMap(() => this.updates.activateUpdate()),
			  map(() => location.reload())
			);
		  })
		)
		.subscribe();
	}
}
