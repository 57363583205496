import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { FileResponse } from '@frontend/shared/models';


@Component({
	selector: 'app-doc-viewer',
	templateUrl: 'doc-viewer.component.html',
	styleUrls: ['doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit, OnDestroy {
	objectUrl: string;

	url: SafeResourceUrl;

	@Input()
	model: FileResponse;

	constructor(
		private sanitizer: DomSanitizer
	) {
	}

	ngOnInit() {
		this.objectUrl = window.URL.createObjectURL(this.model.blob)
		this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.objectUrl);
	}

	ngOnDestroy() {
		window.URL.revokeObjectURL(this.objectUrl);
	}
}
