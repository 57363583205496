import { Component, Input, OnInit, ContentChildren, QueryList } from '@angular/core';

@Component({
	selector: 'app-review',
	templateUrl: 'review.component.html'
})
export class ReviewComponent implements OnInit {
	@Input()
	model: any;

	@Input()
	labels: any = [];

	@Input()
	heading: string;

	constructor() {
	}

	ngOnInit() {
	}
}
