import { Directive, Input, ViewContainerRef, TemplateRef } from "@angular/core";

import { AuthService } from '@frontend/core/services';
import { PermissionType } from '@frontend/permissions/models';

type PermissionTypes = keyof typeof PermissionType;

export interface ClubPermissionDirectiveConfig {
	clubID: number;
	permission: PermissionTypes;
}

@Directive({
	selector: '[hasClubPermission]'
})
export class HasClubPermissionDirective {
	@Input('hasClubPermission')
	public set permissions(value: ClubPermissionDirectiveConfig) {
		let clubID = value.clubID;
		let type = PermissionType[value.permission];

		this.authService.changes().subscribe(state => {
			if (this.authService.hasClubPermission(clubID, type)) {
				this.viewContainer.createEmbeddedView(this.template);
			} else {
				this.viewContainer.clear();
			}
		});
	}

	public constructor(
		private authService: AuthService,
		private template: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
	}
}
