import { Component, Input, OnInit, Host } from '@angular/core';

import { ReviewComponent } from './review.component';

type RowType = 'yesno' | 'number' | 'money' | 'percent' | 'date' | 'datetime' | 'lookup' | 'value' | 'content' | 'text';

interface LookupOptions {
	source: any[];
	key: string;
	display: string;
}

@Component({
	selector: 'app-review-row',
	templateUrl: 'review-row.component.html'
})
export class ReviewRowComponent implements OnInit {
	public get innerValue() {
		if (this.review) {
			if (this.for) {
				let path = this.for.split('.');
				let value = path.reduce((source, property) => source[property], this.review.model);

				return value;
			} else {
				return this.value;
			}
		}

		return;
	}

	@Input()
	for: string;

	@Input()
	depends: string;

	@Input()
	negate: boolean;

	@Input()
	type: RowType = 'text';

	@Input()
	highlight: boolean;

	@Input()
	strong: boolean = false;

	@Input()
	labelClass: any = 'col-12 text-end';

	@Input()
	valueClass: any = 'col-12 fw-bolder';

	@Input()
	lookup: LookupOptions;

	@Input()
	value: any;

	constructor(
		public review: ReviewComponent
	) {
	}

	ngOnInit() {
	}
}
