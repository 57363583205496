import { Component, Input, ViewChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-page-action',
	templateUrl: 'page-list-action.component.html',
	styleUrls: ['page-list-action.component.scss']
})
export class PageListActionComponent {
	@ViewChild(TemplateRef, { static: true })
	public template: TemplateRef<{}>;
}
