import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RestService } from '@frontend/shared/services/rest.service';
import { ResourceParameters, ResourceResponse } from '@frontend/shared/models';

import { Club, ClubList, ClubStatus, ClubAncillary, ApplicationEditStatus } from '@frontend/clubs/models';

@Injectable({ providedIn: 'root' })
export class ClubsService extends RestService<Club> {
	protected urlPrefix: string = '/api/clubs';

	status(id: number, params?: ResourceParameters): Observable<ResourceResponse<ClubStatus>> {
		return this.http.get<ResourceResponse<ClubStatus>>(this.getUrl(id, 'status'), {
			params: this.getParams(params)
		});
	}

	appEditStatus(id: number, params?: ResourceParameters): Observable<ResourceResponse<ApplicationEditStatus[]>> {
		return this.http.get<ResourceResponse<ApplicationEditStatus[]>>(this.getUrl(id, 'application-edit-status'), {
			params: this.getParams(params)
		});
	}

	ancillary(id: number, params?: ResourceParameters): Observable<ResourceResponse<ClubAncillary>> {
		return this.http.get<ResourceResponse<ClubAncillary>>(this.getUrl(id, 'ancillary'), {
			params: this.getParams(params)
		});
	}

	names(params?: ResourceParameters): Observable<ResourceResponse<ClubList[]>> {
		return this.http.get<ResourceResponse<ClubList[]>>(this.getUrl('names'), {
			params: this.getParams(params)
		});
	}
}
