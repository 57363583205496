import { Component, Input, OnInit } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-page-edit-errors',
	templateUrl: 'page-edit-errors.component.html'
})
export class PageEditErrorsComponent implements OnInit {
	@Input()
	form: NgForm;

	@Input()
	labels: any;

	@Input()
	errors: any;

	constructor(
	) { }

	ngOnInit() {
	}

	getFormErrors() {
		let errors = [{
			key: null,
			control: { errors: this.form.errors }
		}];

		for (let controlName of Object.keys(this.form.controls)) {
			let control = this.form.controls[controlName] as UntypedFormControl;

			if (control.invalid) {
				errors.push({
					key: controlName,
					control: control
				});
			}
		}

		if (this.labels) {
			let labels = Object.keys(this.labels);

			return errors.sort((a, b) => {
				let aIndex = labels.indexOf(a.key);
				let bIndex = labels.indexOf(b.key);

				if (aIndex > bIndex) {
					return 1;
				}

				if (aIndex < bIndex) {
					return -1;
				}

				return 0;
			});
		}

		return errors;
	}
}
