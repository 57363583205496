import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@frontend/core/services';
import { PermissionType } from '@frontend/permissions/models';

@Injectable({ providedIn: 'root' })
export class ClubPermissionGuard implements CanActivate, CanActivateChild {
	public constructor(
		private auth: AuthService,
		private router: Router
	) { }

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.auth.isAuthenticated === false) {
			this.router.navigate(['/account/sign-in'], {
				queryParams: { returnUrl: state.url }
			});
		}

		let data = route.data[this.auth.clubPermissionsClaimName];

		if (data) {
			let paramClubID = data['paramClubID'] || 'id';

			let clubID = route.parent.params[paramClubID] || route.params[paramClubID];
			let permissions = data['permissions'] as Array<PermissionType>;

			if (clubID && permissions) {
				let hasAccess = permissions.some((type) => {
					return this.auth.hasUserPermission(type) || this.auth.hasClubPermission(clubID, type);
				});

				if (hasAccess) {
					return true;
				}
			}
		}

		// TODO: Add alert with message 'Access denied.';

		return false;
	}

	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
