import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, distinctUntilChanged, take, tap } from 'rxjs/operators';

import { RenewalYearsService } from '@frontend/shared/services/renewal-years.service';
import { RenewalYear } from '@frontend/shared/models';

@Injectable()
export class RenewalYearsResolver implements Resolve<RenewalYear> {
	public constructor(
		private renewalYearsService: RenewalYearsService
	) {
	}

	public resolve(route: ActivatedRouteSnapshot): Observable<RenewalYear> {
		if (!this.renewalYearsService.renewalYears.value) {
			this.renewalYearsService.init();
		}

		let observable = this.renewalYearsService.selectedYear.pipe(
			filter(renewalYear => renewalYear != null),
			distinctUntilChanged(),
			take(1)
		);

		observable.pipe(
			tap(response => {
				console.log(response);
			})
		);

		return observable;
	}
}
