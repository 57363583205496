import { Component, Input, OnInit, ContentChild } from '@angular/core';

import { PageEditTitleComponent } from './page-edit-title.component';
import { PageEditActionsComponent } from './page-edit-actions.component';

@Component({
	selector: 'app-page-edit',
	templateUrl: 'page-edit.component.html',
	styleUrls: ['page-edit.component.scss']
})
export class PageEditComponent implements OnInit {
	@Input()
	model: any;

	@Input()
	loadingMessage: string = 'loading...';

	@Input()
	lastDraftSave: Date = null;

	@ContentChild(PageEditTitleComponent, { static: true })
	title: PageEditTitleComponent;

	@ContentChild(PageEditActionsComponent, { static: false })
	actions: PageEditActionsComponent;

	ngOnInit() {
	}
}
