<ng-container *ngIf="form?.invalid || errors?.length > 0">
	<div class="row my-3">
		<div class="col-24">
			<div class="alert alert-danger">
				<!-- UI errors -->
				<ng-container *ngIf="form?.invalid">
					<ng-container *ngFor="let error of getFormErrors()">
						<ng-container *ngFor="let errorType of error.control.errors | keyvalue">
							<ng-container [ngSwitch]="errorType.key">
								<div *ngSwitchCase="'required'">
									"{{ labels ? labels[error.key] : error.key }}" is required
								</div>

								<div *ngSwitchCase="'min'">
									"{{ labels ? labels[error.key] : error.key }}" should be greater than {{ error.control.errors.min }}
								</div>

								<div *ngSwitchCase="'max'">
									"{{ labels ? labels[error.key] : error.key }}" should be less than {{ error.control.errors.max }}
								</div>

								<div *ngSwitchCase="'whitespaces'">
									"{{ labels ? labels[error.key] : error.key }}" should not contain only whitespaces
								</div>

								<div *ngSwitchCase="'shouldContainText'">
									"{{ labels ? labels[error.key] : error.key }}" should be the name(s) of the country/countries to where the destination(s) will be
								</div>

								<div *ngSwitchDefault>
									{{ labels && labels[errorType.key] ? labels[errorType.key] : errorType.value }}
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>

				<hr class="my-3" *ngIf="form?.invalid && errors?.length > 0" />

				<!-- API/Custom errors -->
				<ng-container *ngIf="errors?.length > 0">
					<ng-container *ngFor="let error of errors">
						<div>{{ error }}</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>
