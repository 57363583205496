<ng-container *ngIf="model; else loading;">
	<ng-container *ngTemplateOutlet="title.template"></ng-container>

	<ng-content></ng-content>

	<ng-container *ngIf="actions">
		<ng-container *ngTemplateOutlet="actions.template"></ng-container>
	</ng-container>

	<ng-container *ngIf="lastDraftSave">
		<div class="text-muted mt-3" *ngIf="lastDraftSave">
			Last saved: {{ lastDraftSave | date:'shortTime' }}
		</div>
	</ng-container>
</ng-container>

<ng-template #loading>
	<app-spinner [message]="loadingMessage"></app-spinner>
</ng-template>
