import { Component, Input } from '@angular/core';

import { ApprovalCount } from '@frontend/approvals/models';

@Component({
	selector: 'app-status-badge',
	templateUrl: 'status-badge.component.html',
	styleUrls: ['status-badge.component.scss']
})
export class StatusBadgeComponent {
	public get badgeColor(): string {
		if (this.type === 'none') {
			return 'text-bg-dark';
		}

		if (this.model.notStarted) {
			return 'text-bg-default';
		}

		if (this.type === 'single') {
			if (this.reviewable) {
				if (this.model.approved === this.model.total) {
					return 'text-bg-success';
				}

				if (this.model.rejected > 0) {
					return 'text-bg-danger';
				}

				if (this.model.submitted > 0) {
					return 'text-bg-warning';
				}

				if (this.model.draft > 0) {
					return 'text-bg-default';
				}

				return 'text-bg-success';
			}

			return 'text-bg-default';
		}

		if (this.type === 'multi') {
			if (this.model.approved === this.model.total) {
				return 'text-bg-success';
			}

			if (this.model.rejected > 0) {
				return 'text-bg-danger';
			}

			if ((this.model.submitted + this.model.approved) > 0) {
				return 'text-bg-warning';
			}

			if ((this.model.submitted + this.model.approved) === 0) {
				return 'text-bg-default';
			}

			return 'text-bg-success';
		}

		return 'text-bg-default';
	};

	@Input()
	public model: ApprovalCount;

	@Input()
	public type: 'single' | 'multi' | 'none' = 'single';

	@Input()
	public reviewable: boolean = false;
}
