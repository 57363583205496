import { Component, ViewChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-data-column-heading',
	templateUrl: 'data-column-heading.component.html'
})
export class DataColumnHeadingComponent {
	@ViewChild(TemplateRef, { static: true })
	public template: TemplateRef<{}>;
}
