import { Component, Input, ViewChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-page-toolbar',
	templateUrl: 'page-list-toolbar.component.html',
	styleUrls: ['page-list-toolbar.component.scss']
})
export class PageListToolbarComponent {
	@Input()
	public class: string | string[] | {} = 'justify-content-between';

	@ViewChild(TemplateRef, { static: true })
	public template: TemplateRef<{}>;
}
