import { Component, Input, forwardRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { formatDate } from '@angular/common';

import { AbstractInput } from '../abstract/input';

export const DATE_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputDateComponent),
	multi: true
};

export const DATE_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputDateComponent),
	multi: true,
};

@Component({
	selector: 'app-input-date',
	templateUrl: 'date.component.html',
	styleUrls: ['date.component.scss'],
	providers: [DATE_VALUE_ACCESSOR, DATE_VALUE_VALIDATOR],
	encapsulation: ViewEncapsulation.None
})
export class InputDateComponent extends AbstractInput<string> {
	@Input()
	min: string = null;

	@Input()
	max: string = null;

	@Input()
	format: string = 'MM/dd/yyyy';

	@Input()
	locale: string = 'en-US';

	@Input()
	placeholder: string = 'mm/dd/yyyy';

	@Input()
	public required: boolean = false;

	@Input()
	public readonly: boolean = false;

	validate(control: UntypedFormControl): ValidationErrors | null {
		if (!control.value) {
			return null;
		}

		let valueDate = new Date(formatDate(control.value, this.format, 'en-US'));

		if (valueDate) {
			let minDate = new Date(formatDate(this.min, this.format, 'en-US'));

			if (this.min) {
				if (valueDate < minDate) {
					return {
						min: formatDate(this.min, this.format, 'en-US')
					}
				}
			}

			if (this.max) {
				let maxDate = new Date(formatDate(this.max, this.format, 'en-US'));

				if (valueDate > maxDate) {
					return {
						max: formatDate(this.max, this.format, 'en-US')
					}
				}
			}
		}

		return null;
	}
}
