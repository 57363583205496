import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { AbstractInput } from '../abstract/input';

export const YES_NO_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputYesNoComponent),
	multi: true
};

export const YES_NO_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputYesNoComponent),
	multi: true,
};

@Component({
	selector: 'app-input-yes-no',
	templateUrl: 'yes-no.component.html',
	providers: [YES_NO_VALUE_ACCESSOR, YES_NO_VALUE_VALIDATOR]
})
export class InputYesNoComponent extends AbstractInput<boolean> {
	@Input()
	strongOn?: boolean = null;
	@Output() onChange = new EventEmitter<any>();
}
