import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
	constructor(
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request.clone({
			setHeaders: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Expires': 'Thu, 01 Jan 1970 00:00:00 GMT',
				'If-Modified-Since': '0'
			}
		}));
	}
}

export const NoCacheInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: NoCacheInterceptor,
	multi: true
};
