import { NoCacheInterceptorProvider } from './interceptors/no-cache.interceptor';
import { RenewalYearsInterceptorProvider } from './interceptors/renewal-years.interceptor';

export { NoCacheInterceptorProvider } from './interceptors/no-cache.interceptor';
export { RenewalYearsInterceptorProvider } from './interceptors/renewal-years.interceptor';

export const INTERCEPTORS: any[] = [
	NoCacheInterceptorProvider,
	RenewalYearsInterceptorProvider
];
