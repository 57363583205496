import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '@frontend/shared';

import { PAGES } from './pages';
import { COMPONENTS } from './components';
import { HANDLERS } from './handlers';
import { INTERCEPTORS } from './interceptors';
import { GUARDS } from './guards';

@NgModule({
	declarations: [
		PAGES,
		COMPONENTS
	],
	imports: [
		CommonModule,
		FormsModule,

		SharedModule
	],
	exports: [
		COMPONENTS
	],
	providers: [
		HANDLERS,
		INTERCEPTORS,
		GUARDS
	]
})
export class CoreModule {
}
