import { Directive, OnInit, OnDestroy } from "@angular/core";
import { NgModel } from "@angular/forms";

import { Subscription } from "rxjs";

@Directive({
	selector: '[shouldContainText]'
})
export class ShouldContainTextDirective implements OnInit, OnDestroy {
	private valueChanges$: Subscription;

	constructor(
		private model: NgModel
	) {
	}

	ngOnInit() {
		this.valueChanges$ = this.model.valueChanges.subscribe((value: string) => {
			let re = new RegExp(/[A-Z]+/ig);

			if (value && !re.test(value)) {
				this.model.control.setErrors({ 'shouldContainText': true });
			}
		});
	}

	ngOnDestroy() {
		this.valueChanges$.unsubscribe();
	}
}
