export class TextMask {
	static readonly DateMask: string = 'M0/d0/0000';
	static readonly YearMask: string = '0000';
	static readonly ZipMask: string = '00000||00000-0000';
	static readonly FeinMask: string = '00-0000000';
	static readonly VinMask: string = 'WWWVVVVVCYPSSSSSS';

	static readonly VinMaskPattern = {
		'W': { pattern: /[A-HJ-NPR-Z\d]/i }, // wmi
		'V': { pattern: /[A-HJ-NPR-Z\d]/i }, // vds
		'C': { pattern: /[\dX]/i },          // check
		'Y': { pattern: /[A-HJ-NPR-Z\d]/i }, // year
		'P': { pattern: /[A-HJ-NPR-Z\d]/i }, // plant
		'S': { pattern: /[A-HJ-NPR-Z\d]/i }, // seq
	}
}
