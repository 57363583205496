import { Component, Input, forwardRef } from '@angular/core';
import { UntypedFormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { AbstractInput } from '../abstract/input';

export const PERCENT_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputPercentComponent),
	multi: true
};

export const PERCENT_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputPercentComponent),
	multi: true,
};

@Component({
	selector: 'app-input-percent',
	templateUrl: 'percent.component.html',
	providers: [PERCENT_VALUE_ACCESSOR, PERCENT_VALUE_VALIDATOR]
})
export class InputPercentComponent extends AbstractInput<number> {
	get inner(): number {
		if (this.value || this.value == 0) {
			return this.value;
		}

		return null;
	}

	set inner(value: number) {
		this.value = value;

		if (this.min != null && this.value < this.min) {
			this.value = this.min;
		}

		if (this.max != null && this.value > this.max) {
			this.value = this.max;
		}

		this.writeValue(this.value);
	}

	@Input()
	placeholder: string = '%';

	@Input()
	min: number | undefined = 0;

	@Input()
	max: number | undefined = 100;

	validate(control: UntypedFormControl): ValidationErrors | null {
		if (this.min && control.value < this.min) {
			return {
				min: this.min
			};
		}

		if (this.max && control.value > this.max) {
			return {
				max: this.max
			};
		}

		return null;
	}
}
