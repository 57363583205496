import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { AuthService } from '@frontend/core/services';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private authService: AuthService
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(error => {
				// TODO: Check if token is expired

				if (error.status !== 401) {
					return throwError(error);
				}

				if (this.refreshTokenInProgress) {
					return this.refreshTokenSubject.pipe(
						filter(result => result !== null),
						take(1),
						switchMap(() => next.handle(this.refresh(request)))
					);
				}

				this.refreshTokenInProgress = true;

				return throwError(error);
			})
		);
	}

	refresh(request) {
		console.log('REFRESH TOKEN');

		this.authService.refresh();

		return request.clone({
			setHeaders: {
				Authorization: this.authService.token
			}
		});
	}
}

export const RefreshTokenInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: RefreshTokenInterceptor,
	multi: true
};
