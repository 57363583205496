export enum PermissionType {
	Home_View = 41,
	Search_View = 42,
	Approvals_View = 43,
	Resources_View = 44,
	Resources_Edit = 45,
	Reports_View = 46,
	Maintenance_View = 47,
	Maintenance_Edit = 48,
	Profile_View = 49,
	Profile_Edit = 50,
	ClubDetails_View = 51,
	ClubDetails_Edit = 52,
	Fiduciary_View = 53,
	Fiduciary_Edit = 54,
	Fiduciary_Review = 55,
	Foreign_View = 56,
	Foreign_Edit = 57,
	Foreign_Review = 58,
	Headcount_View = 59,
	Headcount_Edit = 60,
	Headcount_Review = 61,
	Vehicles_View = 62,
	Vehicles_Edit = 63,
	Vehicles_Review = 64,
	Events_View = 65,
	Events_Edit = 66,
	Events_Review = 67,
	Locations_View = 68,
	Locations_Edit = 69,
	Payroll_View = 70,
	Payroll_Edit = 71,
	Payroll_Review = 72,
	Crime_View = 84,
	Crime_Edit = 85,
	Crime_Review = 86,
	Media_View = 87,
	Media_Edit = 88,
	Media_Review = 89,
	Pollution_View = 90,
	Pollution_Edit = 91,
	Pollution_Review = 92,
	Values_View = 93,
	Values_Edit = 94,
	Values_Review = 95,
	NamedInsured_View = 96,
	NamedInsured_Edit = 97,
	NamedInsured_Review = 98,
	UserAccounts_View = 99,
	UserAccounts_Edit = 100,
	Documents_View = 101,
	Documents_Edit = 102,
	NamedInsured_SetPastActivation = 103,
	Values_DefaultLocationFields_Edit = 104,
	Reports_ExportCsv = 105,
	Reports_Comparisons = 106,
	Email_Receive = 107,
	Vehicles_SetAnyActivateDeactivateDate = 108,
}
