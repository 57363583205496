import { Injectable, ApplicationRef, EmbeddedViewRef, ComponentRef } from '@angular/core';

import html2pdf from 'html2pdf.js';
import { Observable, Subject } from 'rxjs';

import { FileResponse } from '@frontend/shared/models';

@Injectable({ providedIn: 'root' })
export class PdfService {
	private settings = {
		margin: [20, 0], // jsPDF units
		pagebreak: {
			mode: ['css'],
			before: ['.print-page-break-before'],
			after: ['.print-page-break-after'],
			avoid: ['.print-page-break-avoid', 'p']
		},
		html2canvas: {
			scrollY: 0,
			logging: false
		},
		jsPDF: {
			unit: 'pt',
			format: 'letter',
			orientation: 'portrait'
		},
	}

	constructor(
		private application: ApplicationRef
	) {
	}

	file(component: ComponentRef<any>, delay: number = 250): Promise<File> {
		return new Promise<File>(resolve => {
			this.application.attachView(component.hostView);

			let element = (component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			let wrapper = document.createElement('div');

			wrapper.classList.add('container', 'print');
			wrapper.appendChild(element);

			document.body.appendChild(wrapper);

			setTimeout(() => {
				html2pdf()
					.from(wrapper)
					.set(this.settings)
					.outputPdf('arraybuffer')
					.then((bytes: ArrayBuffer) => {
						component.hostView.destroy();
						document.body.removeChild(wrapper);

						let blob = new Blob([bytes]);
						let file = new File([blob], 'file.pdf', { type: 'application/pdf' });

						resolve(file);
					});
			}, delay);
		});
	}

	print(element: HTMLElement): Promise<any> {
		return new Promise<any>(resolve => {
			let printWindow = window.open('', '', 'left=0,top=0,width=auto,height=auto,toolbar=0,scrollbars=0,status=0');

			printWindow.document.write(`
			<html>
				<head><link type="text/css" href="/print.css" rel="stylesheet" onload="onLoad()" /></head>
				<body>${element.innerHTML}</body>
				<script>
						function onLoad() {
							window.focus();
							window.print();
							window.close();
						}
					</script>
				</html>
			`);
			printWindow.document.close();
		});
	}

	download(element: HTMLElement): Promise<any> {
		let wrapper = document.createElement('div');

		wrapper.classList.add('container', 'print');
		wrapper.appendChild(element.cloneNode(true));
		wrapper.scrollTop = 0;

		document.body.appendChild(wrapper);

		return html2pdf()
			.from(wrapper)
			.set(this.settings)
			.save()
			.then((bytes: ArrayBuffer) => {
				document.body.removeChild(wrapper);
			});
	}

	asFileReponse(element: HTMLElement, fileName: string = 'file.pdf'): Observable<FileResponse> {
		let subject = new Subject<FileResponse>();

		element.classList.add('print');

		html2pdf()
			.set(this.settings)
			.from(element)
			.outputPdf('arraybuffer')
			.then((bytes: ArrayBuffer) => {
				let response = new FileResponse();

				response.blob = new Blob([bytes]);
				response.name = fileName;
				response.type = 'application/pdf';

				subject.next(response);
			});

		return subject;
	}
}
