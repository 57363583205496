import { Component, OnInit } from '@angular/core';

import { AuthService } from '@frontend/core/services';

import { RenewalYearsService, StateService } from '@frontend/shared/services';
import { RenewalYear } from '@frontend/shared/models';

import { ClubsService } from '@frontend/clubs/services';
import { Club } from '@frontend/clubs/models';

import { environment } from '@frontend/environment';

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {
	globalState = this.stateService.globalState;

	user = this.authService.user;
	isTesting = environment.testing;

	renewalYears: RenewalYear[];
	renewalYear: RenewalYear;
	renewalYearID: number;

	club: Club;
	get clubID(): number {
		let clubs = this.authService.getClaim(this.authService.clubPermissionsClaimName);

		if (clubs) {
			let ids = Object.keys(clubs);

			if (ids && ids.length === 1) {
				return +ids[0];
			}
		}

		return null;
	};

	constructor(
		private authService: AuthService,
		private renewalYearService: RenewalYearsService,
		private stateService: StateService,
		private clubsService: ClubsService
	) {
	}

	ngOnInit(): void {
		this.renewalYearService.renewalYears.subscribe(renewalYears => {
			if (renewalYears) {
				this.renewalYears = renewalYears;
			}
		});

		this.renewalYearService.selectedYear.subscribe(renewalYear => {
			if (renewalYear) {
				this.renewalYearID = renewalYear.renewalYearID;
				this.stateService.refresh();

				if (this.clubID) {
					this.clubsService.get(this.clubID).subscribe(response => {
						if (response.success) {
							this.club = response.data;
						}
					})
				}

			}
		});
	}

	onRenewalYearChange() {
		this.renewalYear = this.renewalYears.find(ry => ry.renewalYearID == this.renewalYearID);
		this.renewalYearService.set(this.renewalYear);

		this.stateService.refresh();
	}
}
