import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';
import { JwtModule } from '@auth0/angular-jwt';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgIdleModule } from '@ng-idle/core';

import { CoreModule } from '@frontend/core';
import { SharedModule } from '@frontend/shared';

import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';

import { getAuthToken } from '@frontend/core/services/auth.service';

import { environment } from '@frontend/environment';

 

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
	  BrowserModule,
		BrowserAnimationsModule,

		LoadingBarHttpClientModule,
		LoadingBarRouterModule,

		ToastrModule.forRoot({
			toastClass: 'ngx-toastr app-toast',
			positionClass: 'toast-top-center',
			enableHtml: true,
			preventDuplicates: true,
		}),

		JwtModule.forRoot({
			config: {
				tokenGetter: getAuthToken,
				allowedDomains: environment.authentication.cors
			}
		}),

		ServiceWorkerModule.register('ngsw-worker.js', environment.pwa),

		ModalModule.forRoot(),
		TooltipModule.forRoot(),
		NgIdleModule.forRoot(),

		CoreModule,
		SharedModule,

		AppRoutingModule
	],
 
	bootstrap: [
		AppComponent
	]
})
export class AppModule {
}
