import { Component, Input, forwardRef } from '@angular/core';
import { UntypedFormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { AbstractInput } from '../abstract/input';

export const TEXT_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputTextComponent),
	multi: true
};

export const TEXT_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputTextComponent),
	multi: true,
};

interface MaskPatterns {
	[character: string]: {
		pattern: RegExp,
		optional?: boolean
	}
}

@Component({
	selector: 'app-input-text',
	templateUrl: 'text.component.html',
	providers: [TEXT_VALUE_ACCESSOR, TEXT_VALUE_VALIDATOR]
})
export class InputTextComponent extends AbstractInput<string> {
	@Input()
	mask: string = null;

	@Input()
	patterns: MaskPatterns = null;

	validate(control: UntypedFormControl): ValidationErrors | null {
		if (this.required && this.value) {
			if (this.value.trim() == '') {
				return {
					required: true
				};
			}
		}

		return super.validate(control);
	}
}
