import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { AutosizeModule } from 'ngx-autosize';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';
import { INTERCEPTORS } from './interceptors';
import { GUARDS } from './guards';
import { RESOLVERS } from './resolvers';
import { ICONS } from './icons';
import { ArchwizardModule } from '@y3krulez/angular-archwizard';
import { AppInsightsWrapperService } from './services/app-insights-wrapper.service';

@NgModule({
	declarations: [
		COMPONENTS,
		DIRECTIVES,
		PIPES
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		HttpClientModule,

		FontAwesomeModule,
		NgSelectModule,
		ArchwizardModule,
		MomentModule,
		AutosizeModule,
		CurrencyMaskModule,
		NgxMaskDirective,
		NgxMaskPipe,

		BsDropdownModule.forRoot(),
		AccordionModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TooltipModule.forRoot()
	],
	exports: [
		CommonModule,
		FormsModule,
		RouterModule,
		HttpClientModule,

		FontAwesomeModule,
		NgSelectModule,
		ArchwizardModule,
		MomentModule,
		AutosizeModule,
		CurrencyMaskModule,
		NgxMaskDirective,
		NgxMaskPipe,

		BsDropdownModule,
		AccordionModule,
		BsDatepickerModule,
		TooltipModule,

		COMPONENTS,
		DIRECTIVES,
		PIPES
	],
	providers: [
		INTERCEPTORS,
		GUARDS,
		RESOLVERS,
		AppInsightsWrapperService,
		provideNgxMask()
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(...ICONS);
	}
}
