import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

import { AuthService } from '@frontend/core/services';
import { PermissionType } from '@frontend/permissions/models';

type PermissionTypes = keyof typeof PermissionType;

@Directive({
	selector: '[hasAnyClubPermission]'
})
export class HasAnyClubPermissionDirective {
	@Input('hasAnyClubPermission')
	public set permissions(value: PermissionTypes) {
		let type = PermissionType[value];

		this.authService.changes().subscribe(state => {
			if (this.authService.hasAnyClubPermission(type)) {
				this.viewContainer.createEmbeddedView(this.template);
			} else {
				this.viewContainer.clear();
			}
		});
	}

	public constructor(
		private authService: AuthService,
		private template: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
	}
}
