import {
	faSearch as fasSearch,
	faAdjust as fasAdjust,
	faCircle as fasCircle,
	faCheckCircle as fasCheckCircle,
	faTimesCircle as fasTimesCircle,
	faTh as fasTh,
	faList as fasList,
	faCheck as fasCheck,
	faTimes as fasTimes,
	faHourglass as fasHourglass,
	faSquare as fasSquare,
	faCheckSquare as fasCheckSquare,
	faPen as fasPen,
	faUndo as fasUndo,
	faBan as fasBan,
	faDownload as fasDownload,
	faUserCircle as fasUserCircle,
	faTable as fasTable,
	faAngleDoubleUp as fasAngleDoubleUp,
	faAngleDoubleDown as fasAngleDoubleDown,
	faCircleNotch as fasCircleNotch,
	faPlay as fasPlay,
	faPause as fasPause,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faCircle as farCircle,
	faCalendar as farCalendar,
} from '@fortawesome/pro-regular-svg-icons';

export const ICONS: any[] = [
	fasSearch,
	fasAdjust,
	fasCircle,
	fasCheckCircle,
	fasTimesCircle,
	fasTh,
	fasList,
	fasCheck,
	fasTimes,
	fasHourglass,
	fasSquare,
	fasCheckSquare,
	fasPen,
	fasUndo,
	fasBan,
	fasDownload,
	fasUserCircle,
	fasTable,
	fasAngleDoubleUp,
	fasAngleDoubleDown,
	fasCircleNotch,
	fasPlay,
	fasPause,

	farCircle,
	farCalendar,
];
