import { Component, Input, forwardRef } from '@angular/core';
import { UntypedFormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { AbstractInput } from '../abstract/input';

export const MONEY_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputMoneyComponent),
	multi: true
};

export const MONEY_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputMoneyComponent),
	multi: true,
};

@Component({
	selector: 'app-input-money',
	templateUrl: 'money.component.html',
	providers: [MONEY_VALUE_ACCESSOR, MONEY_VALUE_VALIDATOR]
})
export class InputMoneyComponent extends AbstractInput<number> {
	@Input()
	placeholder: string = '$';

	@Input()
	min: number | undefined = undefined;

	@Input()
	max: number | undefined = undefined;

	validate(control: UntypedFormControl): ValidationErrors | null {
		if (this.min && control.value < this.min) {
			return {
				min: this.min
			};
		}
		if (this.max && control.value > this.max) {
			return {
				max: this.max
			};
		}
		return null;
	}
}
