import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'alert.component.html'
})
export class AlertComponent {
	public heading: SafeHtml;
	public message: SafeHtml;

	public model: PromptOptions;

	public constructor(
		private modalRef: BsModalRef,
		private sanitizer: DomSanitizer
	) { }

	public ngOnInit() {
		if (!this.model.submit) {
			this.model.submit = {
				text: 'OK',
				type: 'primary'
			};
		}

		if (this.model.heading) {
			this.heading = this.sanitizer.bypassSecurityTrustHtml(this.model.heading);
		}

		if (this.model.message) {
			this.message = this.sanitizer.bypassSecurityTrustHtml(this.model.message);
		}
	}

	public onSubmit() {
		this.modalRef.hide();
	}
}
