import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { PromptComponent } from '@frontend/core/components';

@Injectable({ providedIn: 'root' })
export class PromptService {
	private modalRef: BsModalRef;

	public constructor(
		private modalService: BsModalService
	) {
	}

	public show<TResponse = any>(options: PromptOptions, actions?: any): Observable<TResponse> {
		let result = new Subject<TResponse>();

		this.modalRef = this.modalService.show(PromptComponent, {
			ignoreBackdropClick: true,
			initialState: {
				model: options
			}
		});

		this.modalService.onHide.subscribe(response => {
			if (response == 'backdrop-click') {
				result.next(null);
			} else {
				result.next(this.modalRef.content.input || null);
			}

			result.complete();
		});

		return result.asObservable();
	}

	public hide() {
		this.modalRef.hide();
	}
}
