<div class="doc-viewer">
	<ng-container *ngIf="model; else loading">
		<div class="d-flex justify-content-between align-items-center p-3">
			<div class="me-auto h2">
				{{ model.name }}
			</div>

			<div class="ms-auto">
				<a class="btn btn-default" [href]="url" [download]="model.name">
					<fa-icon [icon]="['fas', 'download']" class="me-2"></fa-icon>
					Download Document
				</a>
			</div>
		</div>

		<div class="embed-container">
			<object [data]="url" [type]="model.type">
				<embed [src]="url" [type]="model.type" />
			</object>
		</div>
	</ng-container>
</div>

<ng-template #loading>
	<app-spinner [message]="'loading document...'"></app-spinner>
</ng-template>
