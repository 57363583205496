import { Component, ViewChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-page-edit-actions',
	templateUrl: 'page-edit-actions.component.html',
	styleUrls: ['page-edit-actions.component.scss']
})
export class PageEditActionsComponent {
	@ViewChild(TemplateRef, { static: true })
	template: TemplateRef<any>;
}
