<ng-container *ngIf="data">
	<div class="table-responsive table-responsive-xl">
		<table class="table table-hover data-table" [ngClass]="['table-' + size]">
			<thead [ngClass]="headClass">
				<tr>
					<ng-container *ngIf="selectionOptions.enabled">
						<th class="bg-light py-1" style="width: 1rem;">
							<div>
								<input type="checkbox" class="form-check-input" [checked]="isCheckedAll()" [indeterminate]="isIndeterminate()" (change)="onSelectAll()">
							</div>
						</th>
					</ng-container>

					<th class="py-2" [ngStyle]="getColumnStyles(column)"  *ngFor="let column of columns">
						<ng-container *ngIf="column.headingTemplate">
							<ng-container *ngTemplateOutlet="column.headingTemplate.template; context: { 'column': column }"></ng-container>
						</ng-container>

						<ng-container *ngIf="!column.sort">
							{{ column.heading }} <img *ngIf="column.iconUrl!=''" [src]="column.iconUrl" [alt]="column.iconAlt"  [tooltip]="column.iconTooltip" width="30px" height="30px" />
						</ng-container>

						<ng-container *ngIf="column.sort">
							<a class="d-block" href="javascript:void(0);" (click)="onSortBy(column.sort)">
								{{ column.heading }}

								<ng-container *ngIf="sortedBy.slice(1) == column.sort">
									<span>{{ sortedBy[0] == '+' ? '▾' : '▴' }}</span>
								</ng-container>
							</a>
						</ng-container>
					</th>
				</tr>
			</thead>

			<tbody>
				<ng-container *ngIf="data?.length">
					<ng-container *ngFor="let group of data | groupBy:groupBy">
						<tr *ngIf="groupBy">
							<td [attr.colspan]="columns.length + (selectionOptions.enabled ? 1 : 0)">
								<h5 class="text-uppercase mt-3 mb-0">
									{{ group.key }}
								</h5>
							</td>
						</tr>

						<tr *ngFor="let row of (group.value | orderBy:sortedBy)">
							<ng-container *ngIf="selectionOptions.enabled">
								<td class="bg-light" style="width: 1rem;">
									<div>
										<input type="checkbox" class="form-check-input" [checked]="isSelected(row)" [disabled]="!isCheckable(row)" (change)="onSelect(row)">
									</div>
								</td>
							</ng-container>

							<td *ngFor="let column of columns" [ngStyle]="getColumnStyles(column)" [ngClass]="column.class">
								<ng-container *ngTemplateOutlet="column.template; context: { 'entry': row }"></ng-container>
							</td>
						</tr>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="data?.length === 0">
					<tr>
						<td [attr.colspan]="columns.length + (selectionOptions.enabled ? 1 : 0)">
							<div class="text-center text-muted p-3">
								{{ empty || 'no data' }}
							</div>
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>
</ng-container>
