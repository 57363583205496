import { Component, ViewChild, TemplateRef, Input } from '@angular/core';

@Component({
	selector: 'app-page-wizard-step',
	templateUrl: 'page-wizard-step.component.html',
	styleUrls: ['page-wizard-step.component.scss']
})
export class PageWizardStepComponent {
	@Input()
	title: string = 'Untitled';

	@ViewChild(TemplateRef, { static: true })
	template: TemplateRef<any>;
}
