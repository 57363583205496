import { Directive, Input, ViewContainerRef, TemplateRef } from "@angular/core";

import { RenewalYearsService } from '@frontend/shared/services';
import { AuthService } from '@frontend/core/services';

@Directive({
	selector: '[ifCurrentYear]'
})
export class IfCurrentYearDirective {
	private hasView: boolean = false;

	@Input('ifCurrentYear')
	set shown(value: boolean) {
		// Wrong condition because we it's wrong inside DB/API
		let renewalYear = this.renewalYearsService.renewalYears.value.find(m => m.current);
		let currentYear = this.renewalYearsService.renewalYears.value.find(m => m.year == renewalYear.year - 1);

		this.renewalYearsService.selectedYear.subscribe(year => {
			let canRender = false;

			if (this.authService.hasRole('Administrator')) {
				canRender = true;
			} else {
				canRender = (year.renewalYearID == renewalYear.renewalYearID || year.renewalYearID == currentYear.renewalYearID);
			}

			if (canRender) {
				if (!this.hasView) {
					this.viewContainer.createEmbeddedView(this.template);
					this.hasView = true;
				}
			} else {
				this.viewContainer.clear();
				this.hasView = false;
			}
		});
	}

	constructor(
		private authService: AuthService,
		private renewalYearsService: RenewalYearsService,
		private template: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
	}
}
