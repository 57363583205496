import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'prompt.component.html'
})
export class PromptComponent implements OnInit {
	public model: PromptOptions;

	public input: string = null;
	public errors: { [key: string]: any } = null;

	public constructor(
		private cdr: ChangeDetectorRef,
		private modalRef: BsModalRef
	) { }

	public ngOnInit() {
		if (!this.model.submit) {
			this.model.submit = {
				text: 'Submit',
				type: 'primary'
			};
		}

		if (!this.model.cancel) {
			this.model.submit = {
				text: 'Cancel',
				type: 'link'
			};
		}

		if (this.model.input && this.model.input.value) {
			this.input = this.model.input.value;
			this.onValidate();
		}
	}

	public onSubmit() {
		this.modalRef.hide();
	}

	public onCancel() {
		this.input = null;
		this.modalRef.hide();
	}

	public onValidate() {
		if (this.model.input && this.model.input.validate) {
			this.errors = this.model.input.validate(this.input);
			this.cdr.detectChanges();
		}
	}
}
