<div class="row print-page-break-avoid" [class.mt-4]="heading">
	<div class="col-24">
		<div class="mb-2" [class.bg-highlight]="highlight">
			<ng-container *ngIf="heading">
				<div class="text-center fw-bold w-75 mx-auto mb-1" [innerHTML]="heading"></div>
			</ng-container>

			<ng-content></ng-content>
		</div>
	</div>
</div>

