<div class="row mb-3">
	<label class="col-form-label text-end" [ngClass]="labelClass" [for]="id">
		<ng-container *ngIf="label">
			<span [innerHTML]="label"></span>
		</ng-container>

		<ng-container *ngIf="!label">
			<ng-content></ng-content>
		</ng-container>
	</label>

	<div class="align-self-center" [ngClass]="inputClass">
		<textarea autosize class="form-control"
				  [id]="id"
				  [name]="name"
				  [(ngModel)]="value"
				  [placeholder]="placeholder"
				  [required]="required"
				  [readonly]="readonly"
				  [disabled]="disabled"
				  [rows]="rows"
				  [minRows]="rows"
				  [class.is-invalid]="!isValid"></textarea>

		<div *ngIf="hint" class="form-text text-muted" [innerHTML]="hint"></div>
	</div>
</div>
