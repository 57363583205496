import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UpdateComponent } from '@frontend/core/components';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
	public constructor(
		private injector: Injector,
		private ngZone: NgZone
	) {
	}

	public handleError(error) {
		console.error(error);

		let message = error.toString();

		switch (error.constructor) {
			case ErrorEvent:
				this.showToast('toast-error', 'Error', `: ${error.message}`);
				break;

			case HttpErrorResponse:
				if (error.error && error.error.errors) {
					message = error.error.errors
						.map(e => e.code ? `${e.code}: ${e.description}` : e.description)
						.join('<br />');
				} else {
					message = error.message;
				}

				this.showToast('toast-error', `HTTP ${error.status}`, `${message}`);
				break;

			default:
				if (message.startsWith('Error: Uncaught (in promise): Error: Loading chunk')) {
					let modalService = this.injector.get(BsModalService);

					modalService.show(UpdateComponent, {
						ignoreBackdropClick: true
					});
				}
				break;
		}

	}

	private showToast(
		type: 'toast-error' | 'toast-warning' | 'toast-success' | 'toast-info',
		title: string,
		message: string,
		options?: Partial<IndividualConfig>,
		action?: () => any
	) {
		this.ngZone.run(() => {
			let toastr = this.injector.get(ToastrService);

			toastr.show(message, title, options, type).onTap.subscribe(() => {
				if (action) {
					action();
				}
			});
		});
	}
}

export const ErrorHandlerProvider = {
	provide: ErrorHandler,
	useClass: GlobalErrorHandler,
	multi: false
};
