import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

@Component({
	templateUrl: 'not-found.page.html'
})
export class NotFoundPage implements OnInit {
	constructor(
		private toastr: ToastrService,
		private router: Router
	) {
	}

	ngOnInit() {
		this.toastr.warning('The page you\'re trying to access does not exist.<br />Outdated bookmark?');
		this.router.navigateByUrl('/');
	}
}
